import { Branch, EntityType, GroupWithName, Merchant, SelectedEntity } from 'types/types';
import { isRecordEmpty } from 'utils/utils';

import { BRANCH_GROUPS, MERCHANTS } from './constants';

/**
 * @name isEntityIdValid
 * @description Check if the entity id is valid
 * @param record an object of records against which entity id is checked
 * @param id the entity id which is checked against the record
 */
export const isEntityIdValid = <T>(record: Record<string, T>, id?: string) => {
  if (isRecordEmpty(record)) {
    return false;
  }

  if (!id) {
    return false;
  }

  return record[id];
};

/**
 * @name isSelectedEntityValid
 * @description Check if the selected entity is valid and has id and type
 * @param selectedEntity the selected entity
 * @returns true if the selected entity is valid
 */
export const isSelectedEntityValid = (selectedEntity: SelectedEntity): boolean => {
  if (!selectedEntity.id || !selectedEntity.type) {
    return false;
  }

  return true;
};

/**
 * @name getBranchesByIds
 * @description for the given ids of the branches, the branches will be filtered from the superset of branches
 * @param branchIds the ids of the branches to be filtered
 * @param branches all the branches loaded along with entities
 * @returns the filtered branches as an array
 */
export const getBranchesByIds = (branchIds: number[], branches: Record<string, Branch>) => {
  return branchIds.map((branchId) => branches[branchId]).filter((branch) => branch);
};

/**
 * @name checkDuplicateWithName
 * @description check if there are merchants or branch groups with the same name
 * @param groups the groups to be checked either merchants or branch groups
 * @param name the name to be checked if it is duplicated
 * @returns true if the name is duplicated
 */
export const checkDuplicateWithName = <T extends GroupWithName>(groups: Record<string, T>, name: string) => {
  const names: string[] = Object.values(groups).map((group) =>
    isMerchant(group) ? group.merchantName : group.branchGroupName,
  );

  return names.filter((n) => n === name).length > 1;
};

/**
 * @name isMerchant
 * @description check if the given entity is a merchant
 * @param group the entity to be checked
 * @returns true if the entity is of type Merchant
 */
const isMerchant = (group: GroupWithName): group is Merchant => {
  return (group as Merchant).merchantName !== undefined;
};

/**
 * Validate the given string is of EntityType
 * @param entityType
 * @returns true if the given string is of EntityType
 */
export const isValidEntityType = (entityType: string): entityType is EntityType => {
  return entityType === MERCHANTS || entityType === BRANCH_GROUPS;
};

export const getBranchIdsFromEntity = (branchIds: number[], branches: Record<string, Branch>) => {
  return getBranchesByIds(branchIds, branches)
    .map((branch) => branch.branchId)
    .join(',');
};
