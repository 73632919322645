import { Branch, EntityType, IDropdownOption } from 'types/types';
import { BRANCH_GROUPS, BULLET_POINT_UNICODE, MERCHANTS } from 'utils/constants';

/**
 * Returns an array of dropdown options from an array of branches based on the entity type
 * @param branches branches to be transformed into dropdown options
 * @param entityType type of the entity to which branches belong
 * @returns DropdownOption[] array of dropdown options to display in the dropdown
 */
export const getDropdownOptionsFromBranches = (
  branches: Branch[],
  entityType: EntityType = MERCHANTS,
): IDropdownOption[] => {
  return branches.map(({ branchId, branchName, merchantName, active }) => ({
    value: branchId,
    label: entityType === BRANCH_GROUPS ? `${merchantName} ${BULLET_POINT_UNICODE} ${branchName}` : branchName,
    disabled: !active,
  }));
};
