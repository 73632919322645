import { Disclosure } from '@headlessui/react';
import SVGS from 'views/Logos/logos';

const Header = () => {
  return (
    <Disclosure as="nav" className="bg-liven-gray1 border-b-[1px] border-liven-gray7 w-full fixed ">
      <div className="max-w-full px-5 py-4 sm:px-5 lg:px-5">
        <div className="relative flex items-center justify-between h-6">
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <div className=" flex items-center justify-center">
                <SVGS.LivenFullLogo className="h-6 w-auto" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Disclosure>
  );
};

export default Header;
