import { useAppStore } from 'app-store';
import axios, { AxiosResponse } from 'axios';
import endpoints from 'services/endpoints';

export const logout = (): Promise<AxiosResponse> => {
  return axios.post(endpoints.authentication.LOGOUT);
};

export const resetUserInfo = () => {
  const setUserInfo = useAppStore.getState().resetStore;
  setUserInfo();
};
