import axios, { AxiosResponse } from 'axios';
import endpoints from 'services/endpoints';

interface payload {
  [key: string]: string;
}

export const sendResetPasswordLink = (email: string): Promise<AxiosResponse> =>
  axios.post(endpoints.authentication.SEND_RESET_PASSWORD_LINK, { email });

export const submitResetPassword = ({ password, confirmPassword, token }: payload): Promise<AxiosResponse> =>
  axios.post(endpoints.authentication.SUBMIT_RESET_PASSWORD, {
    password,
    confirmPassword,
    token,
  });
