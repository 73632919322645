import { FallbackError } from 'components/common/fallback-error';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

type Props = {
  title: string;
  description: string;
};

export const RightContentContainer = ({ title, description }: Props) => {
  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col w-full h-full">
        <div className="px-12 pt-8 pb-5 border-b border-b-1 border-solid border-liven-gray5 bg-liven-gray1">
          <h1 className="text-3xl font-medium">{title}</h1>
        </div>
        <div className="bg-liven-gray2 flex flex-col px-12 pt-8 pb-5 h-full">
          <p className="text-lg mt-1.5 leading-6">{description}</p>
          <div className="h-full">
            <ErrorBoundary fallback={<FallbackError />}>
              <Outlet />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
};
