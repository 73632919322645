import moment from 'moment';
import * as Yup from 'yup';

export type CreateFormStructure = {
  buy: string;
  get: string;
  startDate: Date | null;
  endDate: Date | null;
  days: string[];
  startTime: Date | null;
  endTime: Date | null;
  units: string;
  userCap: boolean;
};

export const createForm = {
  initialValues: {
    buy: '',
    get: '',
    startDate: null,
    endDate: null,
    days: [],
    startTime: null,
    endTime: null,
    units: '',
    userCap: false,
  },
  validationSchema: Yup.object().shape({
    buy: Yup.number()
      .min(20, 'The minimum buy amount is $20. Please ensure the buy amount is $20 or greater.')
      .max(5000, 'The maximum buy amount is $5000. Please ensure the buy amount is $5000 or lesser')
      .integer('Must be whole dollars')
      .required('The buy amount is required'),
    get: Yup.number()
      .min(20, 'The minimum buy amount is $20. Please ensure the buy amount is $20 or greater.')
      .max(9999, 'The maximum buy amount is $9999. Please ensure the buy amount is $9999 or lesser')
      .when('buy', (buy, schema) => {
        const min = (buy[0] * 110) / 100;
        const max = buy[0] * 2;
        return schema
          .min(
            min,
            'Your bonus is too small. If you would like to create a Brandollar package that is less than 10%, please contact support.',
          )
          .max(
            max,
            'Your bonus is too large. If you would like to create a Brandollar package that is greater than 100%, please contact support.',
          );
      })
      .integer('Must be whole dollars')
      .required('The get amount is required'),
    startDate: Yup.date().nullable(),
    endDate: Yup.date()
      .nullable()
      .when('startDate', (startDate, schema) => {
        if (startDate[0])
          return schema.min(
            moment(startDate[0]).add(1, 'minute').toDate(),
            `Ending date needs to be after ${moment(startDate[0]).format('DD/MM/yyyy hh:mma')}`,
          );
        return schema;
      }),
  }),
};
