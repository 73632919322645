import { ChartLoader } from 'components/animation/loader/chart-loader';
import { ChartRetry } from 'components/animation/loader/chart-retry';
import React from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import { CHART_COLORS } from 'utils/constants';

import styles from './stackedareachart.module.css';

type PeriodPayload = {
  value?: Number | null;
  tooltipLabel?: string | null;
};
export type StackedAreaChartData = {
  [key: string]: PeriodPayload | null | string;
  interval: string | null;
  tooltipHeader: string | null;
};

export const CustomTooltip = <T extends ValueType, V extends NameType>({ active, payload }: TooltipProps<T, V>) => {
  if (!active || !payload || payload.length === 0) return null;
  const tooltipHeader = payload[0].payload.tooltipHeader;

  return (
    <div className={styles.customTooltip}>
      <div className={styles.customTooltipHeaderContainer}>
        <div className={styles.customTooltipHeading}>{tooltipHeader}</div>
      </div>
      <div className={styles.customTooltipRowsContainer}>
        {payload
          .sort((a, b) => ((b?.value as number) ?? 0) - ((a?.value as number) ?? 0))
          .map(({ color, value, dataKey, payload: payloadData }) => {
            if (typeof dataKey === 'number' || value === 0) return null;

            const periodKey = dataKey?.split('.')[0];
            return (
              <div key={color} className={styles.customTooltipRow}>
                <div className={styles.customTooltipRowLabel}>
                  <span
                    className={styles.customTooltipStraightLine}
                    style={{
                      background: color,
                    }}
                  />
                  <div>{value}</div>
                </div>
                <div className={styles.customTooltipPeriodLabel}>{payloadData[periodKey ?? '']?.tooltipLabel}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

interface IStackedAreaChartProps extends Pick<CategoricalChartProps, 'margin'> {
  data: StackedAreaChartData[] | undefined;
  columns: string[];
  colors?: Record<string, string>;
  loading?: boolean;
  primaryColor?: string;
  secondaryColor?: string;
  error?: boolean;
  hideYAxis?: boolean;
  hideXAxis?: boolean;
  onErrorRetry?: () => void;
  stacked?: boolean;
  height?: number;
  prefixYAxis?: string;
}

export const StackedAreaChart: React.FC<IStackedAreaChartProps> = ({
  data,
  columns,
  colors,
  loading,
  error,
  onErrorRetry,
  hideYAxis,
  hideXAxis,
  margin,
  height,
  stacked = false,
  prefixYAxis,
}) => {
  const DEFAULT_MARGIN = { top: 48, right: 0, left: 0, bottom: 0 };
  const DEFAULT_HEIGHT = 352;

  return (
    <div className="relative" style={{ ...(height && { height }) }}>
      {loading && <ChartLoader />}
      {error && <ChartRetry onErrorRetry={onErrorRetry} />}
      <ResponsiveContainer width="100%" height={height ?? DEFAULT_HEIGHT}>
        <AreaChart data={data} margin={margin ?? DEFAULT_MARGIN}>
          <XAxis
            dataKey="interval"
            tickMargin={8}
            stroke="var(--gray7)"
            tick={{ fill: 'var(--gray11)' }}
            style={{
              fontSize: '0.75em',
            }}
            hide={hideXAxis}
          />
          <YAxis
            tickMargin={8}
            tickFormatter={(value) => `${prefixYAxis}${value}`}
            stroke="var(--gray7)"
            tick={{ fill: 'var(--gray11)' }}
            style={{
              fontSize: '0.75em',
            }}
            hide={hideYAxis}
            width={70}
          />
          <CartesianGrid strokeDasharray="1 3" stroke="var(--gray8)" />
          <Tooltip content={<CustomTooltip />} wrapperStyle={{ zIndex: 1 }} />
          {data ? (
            <>
              {columns.map((col, index) => (
                <>
                  <defs key={`defs-${index}`}>
                    <linearGradient id={`${index}`} x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor={colors?.[col] ?? CHART_COLORS[index]} stopOpacity={0.1} />
                      <stop offset="95%" stopColor={colors?.[col] ?? CHART_COLORS[index]} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <Area
                    key={`area-${col}`}
                    type="monotoneX"
                    dataKey={`${col}.value`}
                    stroke={colors?.[col] ?? CHART_COLORS[index]}
                    fillOpacity={stacked ? 0.2 : 1}
                    fill={stacked ? colors?.[col] ?? CHART_COLORS[index] : `url(#${index}`}
                    strokeWidth="2"
                    stackId={stacked ? '1' : undefined}
                  />
                </>
              ))}
            </>
          ) : null}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
