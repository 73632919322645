import Header from 'views/Header/header';
import { LoginForm } from './LoginForm/login-form';

const Login = () => {
  return (
    <>
      <Header />
      <LoginForm />
    </>
  );
};

export default Login;
