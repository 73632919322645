import { ReactComponent as FoodollarSVG } from 'static/liven/assets/svg/foodollar.svg';

type Props = {
  className?: string;
};

export const Foodollars = ({ className = '' }: Props) => {
  return (
    <>
      <FoodollarSVG title="Foodollar" className={className} />
    </>
  );
};
