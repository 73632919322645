

import { Foodollars } from './foodollar';
import { Live } from './live';
import { LivenFullLogo } from './liven-full-logo';
import { ThreeLayers } from './three-layers';

const Logos = {
  LivenFullLogo,
  Foodollars,
  Live,
  ThreeLayers,
};

export default Logos;
