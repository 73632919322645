import React from 'react';

import styles from './loader.module.css';

type Props = {
  onErrorRetry?: () => void;
};

export const ChartRetry: React.FC<Props> = ({ onErrorRetry }) => {
  return (
    <div className={styles.loadingContainer} onClick={onErrorRetry}>
      <div className={styles.shinyBoxContainer}>
        <div className={styles.errorBox}>
          Something went wrong.
          <br />
          Please try again.
        </div>
      </div>
    </div>
  );
};
