import styles from 'components/charts/composed-chart.module.css';
import { Empty } from 'components/charts/shared/empty';
import React from 'react';
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart as RechartsComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import { ChartColors, IComposedChartData } from 'types/types';

import { CustomTooltip } from './tooltip/custom-tooltip';

interface IChartDataProps extends Pick<CategoricalChartProps, 'margin'> {
  isLoading?: boolean;
  error?: boolean;
  hideXAxis?: boolean;
  hideYAxis?: boolean;
  onErrorRetry?: () => void;
  tooltipTitle: string;
  height?: string;
  chartColors: ChartColors;
  data?: IComposedChartData[];
  selected?: string;
}

export const ChartTopBar: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className={styles.topBar}>{children}</div>;
};

export const ChartNavigation: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className={styles.chartNav}>{children}</div>;
};

export const ChartModifiersContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className={styles.modifiersContainer}>{children}</div>;
};

export const ComposedChart: React.FC<IChartDataProps> = ({
  tooltipTitle,
  data,
  isLoading,
  error,
  onErrorRetry,
  margin,
  height,
  chartColors,
  selected,
}) => {
  const DEFAULT_MARGIN = { top: 48, right: 0, left: 0, bottom: 0 };
  const DEFAULT_HEIGHT = 352;
  const { colors, gradients } = chartColors;
  const DEFAULT_LINE_CHART_ONE_GRADIENT = '#fe3bff';
  const DEFAULT_LINE_CHART_TWO_GRADIENT = '#f33e75';
  const DEFAULT_LINE_CHART_ONE_PRIMARY_COLOR = '#FF3C6E';
  const DEFAULT_LINE_CHART_TWO_PRIMARY_COLOR = '#FF3CFF';
  const DEFAULT_BAR_CHART_ONE_PRIMARY_COLOR = '#2cb5cb';

  return (
    <div className={styles.chartWrapper} style={{ ...(height && { height }) }}>
      {isLoading && (
        <div className={styles.loadingContainer}>
          <div className={styles.shinyBoxContainer}>
            <div className={styles.shinyBox}>Loading...</div>
          </div>
        </div>
      )}
      {error && (
        <div className={styles.loadingContainer} onClick={onErrorRetry}>
          <div className={styles.shinyBoxContainer}>
            <div className={styles.errorBox}>
              Something went wrong.
              <br />
              Please try again.
            </div>
          </div>
        </div>
      )}
      {data?.length === 0 && <Empty />}
      <ResponsiveContainer width="100%" height={height ?? DEFAULT_HEIGHT}>
        <RechartsComposedChart data={data} margin={margin ?? DEFAULT_MARGIN}>
          <defs>
            <linearGradient id="lineChartOneGradient" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={gradients?.lineChartOneGradient || DEFAULT_LINE_CHART_ONE_GRADIENT}
                stopOpacity={0.2}
              />
              <stop
                offset="95%"
                stopColor={gradients?.lineChartOneGradient || DEFAULT_LINE_CHART_ONE_GRADIENT}
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="lineChartTwoGradient" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={gradients?.lineChartTwoGradient || DEFAULT_LINE_CHART_TWO_GRADIENT}
                stopOpacity={0.2}
              />
              <stop
                offset="95%"
                stopColor={gradients?.lineChartTwoGradient || DEFAULT_LINE_CHART_TWO_GRADIENT}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="interval"
            tickMargin={8}
            stroke="var(--gray7)"
            tick={{ fill: 'var(--gray11)' }}
            style={{
              fontSize: '0.75rem',
            }}
            minTickGap={0}
          />
          <YAxis
            yAxisId="left"
            orientation="left"
            stroke="var(--gray7)"
            tick={{ fill: 'var(--gray11)' }}
            style={{ fontSize: '0.75rem' }}
            tickFormatter={(value) => `$${value}`}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            stroke="var(--gray7)"
            tick={{ fill: 'var(--gray11)' }}
            style={{ fontSize: '0.75rem' }}
          />
          <CartesianGrid strokeDasharray="1 3" stroke="#d7dbdf" />
          {data &&
            data.length > 0 &&
            Object.keys(data[0]).includes('barChartOneThisPeriod') &&
            (!selected || (selected && data[0]['barChartOneThisPeriod']?.tooltipLabel === selected)) && (
              <Bar
                dataKey={'barChartOneThisPeriod.value'}
                barSize={20}
                fill={colors?.barChartOnePrimaryColor || DEFAULT_BAR_CHART_ONE_PRIMARY_COLOR}
                yAxisId="right"
                radius={4}
              />
            )}
          {data &&
            data.length > 0 &&
            Object.keys(data[0]).includes('lineChartOneThisPeriod') &&
            (!selected || (selected && data[0]['lineChartOneThisPeriod']?.tooltipLabel === selected)) && (
              <Area
                type="monotone"
                dataKey={'lineChartOneThisPeriod.value'}
                stroke={colors?.lineChartOnePrimaryColor || DEFAULT_LINE_CHART_ONE_PRIMARY_COLOR}
                fillOpacity={1}
                fill="url(#lineChartOneGradient)"
                strokeWidth="3"
                yAxisId="left"
              />
            )}
          {data &&
            data.length > 0 &&
            Object.keys(data[0]).includes('lineChartTwoThisPeriod') &&
            (!selected || (selected && data[0]['lineChartTwoThisPeriod']?.tooltipLabel === selected)) && (
              <Area
                type="monotone"
                dataKey={'lineChartTwoThisPeriod.value'}
                stroke={colors?.lineChartTwoPrimaryColor || DEFAULT_LINE_CHART_TWO_PRIMARY_COLOR}
                fillOpacity={1}
                fill="url(#lineChartTwoGradient)"
                strokeWidth="3"
                yAxisId="left"
              />
            )}
          <Tooltip content={<CustomTooltip />} />
        </RechartsComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export const ComposedChartContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className={styles.chartContainer}>{children}</div>;
};

export const ComposedChartInsightContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className={styles.chartContainerInsight}>{children}</div>;
};
