import { changePassword } from 'services/authentication/change-password';
import { login } from 'services/authentication/login';
import { logout } from 'services/authentication/logout';
import { sendResetPasswordLink, submitResetPassword } from 'services/authentication/reset-password';
import { validateLogin } from 'services/authentication/validateLogin';

const authenticationService = {
  login,
  logout,
  changePassword,
  sendResetPasswordLink,
  submitResetPassword,
  validateLogin,
};

export default authenticationService;
