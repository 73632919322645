import SVGS from 'views/Logos/logos';

const DesktopRedirectionInfo = () => {
  return (
    <div className="flex h-screen  md:hidden fixed bg-white w-full z-10">
      <div className="m-auto center">
        <SVGS.LivenFullLogo className="h-10 m-auto mb-5"></SVGS.LivenFullLogo>
        <p className="text-center text-base">
          To access the Liven Dashboard, visit:
          <br />
          <span className="text-liven-pink break-all px-2">{`${process.env.REACT_APP_APPLICATION_URL}`}</span> <br />
          on your computer.
        </p>
      </div>
    </div>
  );
};

export default DesktopRedirectionInfo;
