import { Disclosure } from '@headlessui/react';
import { DropdownContent, DropdownItem, DropdownMenu, DropdownTrigger } from '@liven-engineering/liven-react-lib';
import { EntitiesSelector } from 'components/navbar/entities-selector';
import { SignOut, User, UserGear } from 'phosphor-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout, resetUserInfo } from 'services/authentication/logout';
import { Regions } from 'utils/constants';
import { getCookie } from 'utils/cookies';
import SVGS from 'views/Logos/logos';

import { ChangePassword } from './change-password';

const Navbar = () => {
  const navigate = useNavigate();
  const [openPasswordModal, setOpenPasswordModal] = useState<boolean>(false);
  const logoutSession = async () => {
    await logout();
    navigate('/login');
    resetUserInfo();
  };
  const regionCookie = getCookie('region');
  const region = Object.values(Regions).includes(regionCookie as Regions) ? regionCookie : Regions.AU;

  return (
    <Disclosure
      as="nav"
      data-testid="app-nav"
      className="bg-liven-gray1 border-b-[1px] border-liven-gray7 w-full fixed "
    >
      <div className="max-w-full px-5 py-4 sm:px-5 lg:px-5">
        <div className="relative flex items-center justify-between h-6">
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <div className=" flex items-center justify-center">
                <SVGS.LivenFullLogo className="h-6 w-auto" />
              </div>
              <EntitiesSelector />
            </div>
          </div>
          <div className="float-right">
            <DropdownMenu modal={false}>
              <DropdownTrigger>
                <div
                  data-testid="user-menu"
                  className="border border-1 border-liven-gray6 float-right p-2.5 rounded-full cursor-pointer"
                >
                  <User weight="bold" size={20} />
                </div>
              </DropdownTrigger>
              <DropdownContent align="start" className="flex flex-col overflow-auto max-h-96 mr-1 z-20">
                <DropdownItem
                  className="px-[14px]"
                  key={`change-password`}
                  data-testid="change-password"
                  icon={<UserGear size={20} weight="regular" />}
                  onSelect={() => setOpenPasswordModal(true)}
                >
                  Change Password
                </DropdownItem>
                <DropdownItem
                  className="px-[14px"
                  key={`logout`}
                  data-testid="logout"
                  icon={<SignOut size={20} weight="regular" className="text-liven-error-9" />}
                  onSelect={() => logoutSession()}
                >
                  <span className="text-liven-error-9"> Logout</span>
                </DropdownItem>
              </DropdownContent>
            </DropdownMenu>
            <div data-testid="flag" className="border border-1 border-liven-gray6 float-right p-2.5 rounded-full mr-2">
              <img src={`/${region.toLowerCase()}.png`} width={'20px'} alt={region} title={region} />
            </div>
            <ChangePassword openPasswordModal={openPasswordModal} setOpenPasswordModal={setOpenPasswordModal} />
          </div>
        </div>
      </div>
    </Disclosure>
  );
};

export default Navbar;
