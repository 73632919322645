const url =
  process.env.REACT_APP_CONTACT_CLICKUP_EMBEDED ||
  'https://forms.clickup.com/2700544/f/2jd80-240822/B4EVAKAG7VT8QDPK93';

export const Contact = () => (
  <>
    <iframe
      data-testid="click-up-contact-form"
      title="Merchant Brandollar Creation Support"
      className="clickup-embed clickup-dynamic-height"
      src={url}
      width="100%"
      height="100%"
    ></iframe>
  </>
);
