import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTrigger,
  TextInput,
} from '@liven-engineering/liven-react-lib';
import { useMutation } from '@tanstack/react-query';
import { useAppStore } from 'app-store';
import classnames from 'classnames';
import { useAnalytics } from 'config/segment';
import { Form, Formik } from 'formik';
import { CircleNotch, Eye, EyeSlash, UserGear } from 'phosphor-react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { changePassword } from 'services/authentication/change-password';
import * as Yup from 'yup';

type MessageType = 'success' | 'error';

const messageStyle: Record<MessageType, string> = {
  success: 'bg-green-100 text-green-500',
  error: 'bg-red-100 text-red-500',
};

interface IToggles {
  isCurrentPasswordVisible: boolean;
  isNewPasswordVisible: boolean;
  isConfirmNewPasswordVisible: boolean;
}

const updateSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Current password cannot be empty'),
  newPassword: Yup.string()
    .min(8, 'New password must contain minimum 8 characters')
    .max(20, 'New password must contain maximum 20 characters')
    .required('New password cannot be empty')
    .matches(/[0-9]{2}/, 'New password must contain at least 2 digits')
    .matches(/[a-z]/, 'New password must contain at least 1 lowercase character')
    .matches(/[A-Z]/, 'New password must contain at least 1 uppercase character')
    .matches(/^\S*$/, 'Password cannot contain spaces'),
  confirmNewPassword: Yup.string()
    .required('Confirm new password cannot be empty')
    .oneOf([Yup.ref('newPassword')], 'Passwords does not match'),
});

export const ChangePassword = ({
  openPasswordModal,
  setOpenPasswordModal,
}: {
  openPasswordModal: boolean;
  setOpenPasswordModal: (value: boolean) => void;
}) => {
  const [toggles, setToggles] = useState<IToggles>({
    isCurrentPasswordVisible: false,
    isNewPasswordVisible: false,
    isConfirmNewPasswordVisible: false,
  });
  const navigate = useNavigate();
  const { mutate, isLoading, isSuccess, isError } = useMutation(
    ({ currentPassword, newPassword }: { currentPassword: string; newPassword: string }) =>
      changePassword(currentPassword, newPassword),
    {
      onSuccess: () => {
        setTimeout(() => navigate('/login'), 2000);
      },
    },
  );

  const { pathname } = useLocation();
  const userInfo = useAppStore((state) => state.userInfo);

  const analytics = useAnalytics();

  const commonMessageStyle = 'flex flex-col rounded font-bold text-xs py-2 my-2 items-center';
  const divStyle = 'flex flex-row items-center w-full items-center ';

  const handleCurrentPasswordVisibilityToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setToggles((state) => {
      return { ...state, isCurrentPasswordVisible: !state.isCurrentPasswordVisible };
    });
  };

  const handleNewPasswordVisibilityToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setToggles((state) => {
      return { ...state, isNewPasswordVisible: !state.isNewPasswordVisible };
    });
  };

  const handleConfirmNewPasswordVisibilityToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setToggles((state) => {
      return { ...state, isConfirmNewPasswordVisible: !state.isConfirmNewPasswordVisible };
    });
  };

  return (
    <Modal open={openPasswordModal} onOpenChange={setOpenPasswordModal}>
      <ModalTrigger />
      <ModalContent>
        <ModalHeader displayCloseButton>
          <UserGear size={20} weight="light" />
          Change Password
        </ModalHeader>
        <Formik
          initialValues={{
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
          }}
          onSubmit={(values, actions) => {
            mutate(
              { currentPassword: values.currentPassword, newPassword: values.newPassword },
              { onSuccess: () => actions.resetForm() },
            );

            if (userInfo) {
              analytics.track('User Changed Password', {
                user_email: userInfo.email,
                button_identifier: 'update',
                screen: 'Change Password',
                path: pathname,
              });
            }
          }}
          validationSchema={updateSchema}
        >
          {(props) => (
            <Form className="" onSubmit={props.handleSubmit}>
              <ModalBody className="!p-0 w-[400px] !max-w-[500px] ">
                <div className="p-6 pt-4  flex flex-col gap-5 ">
                  <div className={divStyle}>
                    <TextInput
                      type={toggles.isCurrentPasswordVisible ? 'text' : 'password'}
                      label="Enter current password"
                      className="!w-72"
                      name="currentPassword"
                      value={props.values.currentPassword}
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      autoFocus
                      data-testid="currentPassword-text"
                    />
                    <button
                      className="ml-2 mt-5"
                      onClick={handleCurrentPasswordVisibilityToggle}
                      data-testid="currentPassword"
                    >
                      {toggles.isCurrentPasswordVisible ? (
                        <EyeSlash size={24} className="text-gray-400" />
                      ) : (
                        <Eye size={24} className="text-gray-400" />
                      )}
                    </button>
                  </div>
                  <div className={divStyle}>
                    <TextInput
                      type={toggles.isNewPasswordVisible ? 'text' : 'password'}
                      label="Enter new password"
                      className="!w-72"
                      name="newPassword"
                      value={props.values.newPassword}
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      data-testid="newPassword-text"
                    />
                    <button className="ml-2 mt-5" onClick={handleNewPasswordVisibilityToggle} data-testid="newPassword">
                      {toggles.isNewPasswordVisible ? (
                        <EyeSlash size={24} className="text-gray-400" />
                      ) : (
                        <Eye size={24} className="text-gray-400" />
                      )}
                    </button>
                  </div>
                  <div className={divStyle}>
                    <TextInput
                      type={toggles.isConfirmNewPasswordVisible ? 'text' : 'password'}
                      label="Confirm new password"
                      className="!w-72"
                      value={props.values.confirmNewPassword}
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      name="confirmNewPassword"
                      data-testid="confirmNewPassword-text"
                    />
                    <button
                      className="ml-2 mt-5"
                      onClick={handleConfirmNewPasswordVisibilityToggle}
                      data-testid="confirmNewPassword"
                    >
                      {toggles.isConfirmNewPasswordVisible ? (
                        <EyeSlash size={24} className="text-gray-400" />
                      ) : (
                        <Eye size={24} className="text-gray-400" />
                      )}
                    </button>
                  </div>

                  <ul className="text-sm">
                    {!!props.errors.currentPassword && (
                      <li className="text-rose-700">{props.errors.currentPassword}</li>
                    )}
                    {!!props.errors.newPassword && <li className="text-rose-700">{props.errors.newPassword}</li>}
                    {!!props.errors.confirmNewPassword && (
                      <li className="text-rose-700">{props.errors.confirmNewPassword}</li>
                    )}
                  </ul>

                  {isSuccess && (
                    <div className={classnames(commonMessageStyle, messageStyle['success'])}>
                      Password was changed! Redirecting...
                    </div>
                  )}
                  {isError && (
                    <div className={classnames(commonMessageStyle, messageStyle['error'])}>
                      An error occurred, please contact administrator!
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" disabled={isLoading} variant="primary" className="w-fit ">
                  {isLoading && <CircleNotch className="animate-spin" weight="bold" />} Update
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};
