import { ReactComponent as LivenFullLogoSVG } from 'static/liven/assets/svg/liven-full-logo.svg';

type Props = {
  className?: string;
};

export const LivenFullLogo = ({ className = '' }: Props) => {
  return (
    <>
      <LivenFullLogoSVG title="Liven logo" className={className} />
    </>
  );
};
