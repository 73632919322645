import { Dropdown } from 'components/lib/dropdown';
import React, { useMemo } from 'react';
import { IDropdownOption } from 'types/types';

type Props = {
  selectedBranch: string;
  dropdownOptions: IDropdownOption[];
  handleSelectedBranchChanged: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  enableAllBranchesOption?: boolean;
  disabled?: boolean;
};

const allBranchesOption: IDropdownOption = {
  value: 'all',
  label: 'All Branches',
};

export const Branches = ({
  dropdownOptions,
  handleSelectedBranchChanged,
  selectedBranch,
  enableAllBranchesOption,
  disabled,
}: Props) => {
  const branchesOptions = useMemo(() => {
    if (enableAllBranchesOption) {
      return [allBranchesOption, ...dropdownOptions];
    }

    return dropdownOptions;
  }, [dropdownOptions, enableAllBranchesOption]);

  return (
    <>
      <Dropdown
        disabled={disabled}
        id="select-branches"
        handleSelectionChanged={handleSelectedBranchChanged}
        options={branchesOptions}
        labelText=""
        selectedValue={selectedBranch}
      />
    </>
  );
};
