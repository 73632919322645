const endpoints = {
  authentication: {
    LOGIN: '/merchantportal/account/login',
    LOGOUT: '/merchantportal/account/logout',
    CHANGE_PASSWORD: '/merchantportal/account/change/password',
    SEND_RESET_PASSWORD_LINK: '/v1/account/local/reset-password',
    SUBMIT_RESET_PASSWORD: '/v1/account/local/reset-password/submit',
    USER_PROFILE: '/merchantportal/account/profile',
    RESET_PASSWORD: '/v3/account/local/reset_password',
  },
  entities: {
    MERCHANTS: '/merchantportal/merchants',
    BRANCH_GROUPS: '/merchantportal/branch-groups',
    ALL_BRANCHES: '/merchantportal/branches',
  },
  merchants: {
    BRANCHES: (merchantId: string) => `/merchantportal/merchants/${merchantId}/branches`,
    MERCHANTS: '/merchantportal/merchants',
  },
  brandollars: {
    vouchers: (sellerType: string, sellerId: string) => `/merchantportal/vouchers/${sellerType}/${sellerId}`,
    update: (voucherId: number) => `/merchantportal/vouchers/${voucherId}`,
    create: () => `/merchantportal/vouchers`,
  },
  analytics: {
    requestData: (metricsSet: string) => `/merchantportal/metrics/${metricsSet}`,
  },
  statements: (branchId: number) => `/merchantportal/statements/${branchId}`,
};

export default endpoints;
