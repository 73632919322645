function setCookie(name: string, value: string) {
  const now = new Date();
  // Defaults to expire 1 year in the future
  now.setFullYear(now.getFullYear() + 1);
  document.cookie = `${name}=${value};expires=${now.toUTCString()};path="/"`;
}

function getCookie(name: string) {
  name = name + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export { setCookie, getCookie };
