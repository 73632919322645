import { remoteConfig } from 'config/remote';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { useEffect, useState } from 'react';

export function useRemoteConfigValue<T extends boolean | string | number>(flag: string, defaultValue: T) {
  const [value, setValue] = useState<T>(defaultValue);

  useEffect(() => {
    const refreshRemoteConfig = async () => {
      await fetchAndActivate(remoteConfig);
      const sourceValue = getValue(remoteConfig, flag);
      let flagValue;

      if (typeof defaultValue === 'boolean') {
        flagValue = sourceValue.asBoolean();
      } else if (typeof defaultValue === 'string') {
        flagValue = sourceValue.asString();
      } else if (typeof defaultValue === 'number') {
        flagValue = sourceValue.asNumber();
      }

      setValue(flagValue as T);
    };

    refreshRemoteConfig();
  });

  return value;
}
