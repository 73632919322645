import axios from 'axios';
import { useEffect, useState } from 'react';
import endpoints from 'services/endpoints';
import { UserInfo } from 'types/types';

export const useAuthValidator = (onSuccessCallback: (userInfo: UserInfo) => void) => {
  const [isAuthenticated, setAuthenticated] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    (async () => {
      try {
        const response = await axios.get(endpoints.authentication.USER_PROFILE, {
          withCredentials: true,
          signal,
        });

        if (response.status === 200) {
          setAuthenticated(true);
          onSuccessCallback(response.data);
        } else {
          setAuthenticated(false);
        }
      } catch (e) {
        setAuthenticated(false);
      }
    })();

    return () => controller.abort();
  }, [onSuccessCallback]);

  return isAuthenticated;
};
