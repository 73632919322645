import { nomnieLight } from '@liven-engineering/liven-react-lib';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from 'App';
import { AnalyticsProvider } from 'config/segment';
import 'index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from 'reportWebVitals';
import services from 'services/services';
import { ThemeProvider } from 'styled-components';
import { Regions } from 'utils/constants';
import { getCookie } from 'utils/cookies';

const regionCookie = getCookie('region');
const region = Object.values(Regions).includes(regionCookie as Regions) ? regionCookie : Regions.AU;

// setup API defaults
services.settings.setupGlobalAxiosDefaults(region as Regions);
const queryClient = new QueryClient();
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={nomnieLight}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AnalyticsProvider writeKey={process.env.REACT_APP_SEGMENT_ANALYTICS || ''}>
            <App />
          </AnalyticsProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
