import axios from 'axios';
import FormData from 'form-data';
import endpoints from 'services/endpoints';

export const changePassword = (currentPassword: string, newPassword: string): Promise<string> => {
  const requestPayload = new FormData();
  requestPayload.append('old', currentPassword);
  requestPayload.append('new', newPassword);

  return axios
    .post(endpoints.authentication.CHANGE_PASSWORD, requestPayload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((response) => response.data);
};
