import axios from 'axios';
import FormData from 'form-data';
import endpoints from 'services/endpoints';
import { UserInfo } from 'types/types';

export const login = (email: string, password: string): Promise<UserInfo> => {
  const requestPayload = new FormData();
  requestPayload.append('email', email);
  requestPayload.append('password', password);

  return axios
    .post(endpoints.authentication.LOGIN, requestPayload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((response) => response.data);
};
