import { ReactComponent as LiveSVG } from 'static/liven/assets/svg/live.svg';

type Props = {
  className?: string;
};

export const Live = ({ className = '' }: Props) => {
  return (
    <>
      <LiveSVG title="LiveSVG" className={className} />
    </>
  );
};
