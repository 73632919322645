import { getAnalyticsData } from 'services/merchant/analytics/analytics-service';
import { getBranchesForMerchant } from 'services/merchant/branches/branches-service';
import { createBrandDollar, getBrandDollars, updateBrandDollar } from 'services/merchant/brandollars/brandollars-service';
import { getMerchants } from 'services/merchant/merchants/merchants-service';
import { getStatementsForBranch } from 'services/merchant/statements';

const merchantService = {
  getBranchesForMerchant,
  getMerchants,
  getAnalyticsData,
  getStatementsForBranch,
  getBrandDollars,
  updateBrandDollar,
  createBrandDollar,
};

export default merchantService;
