import { ChartNavigationItem } from 'components/charts/chart-navigation/chart-navigation-item';
import { ChartNavigation, ChartTopBar, ComposedChart, ComposedChartContainer } from 'components/charts/composed-chart';
import { ThemeProviderContainer } from 'components/theme/theme-provider-container';
import React, { useState } from 'react';
import { ChartColors, IComposedChartData, INavigationItemTransformedResponse } from 'types/types';

type Props = {
  navigationItemsData?: INavigationItemTransformedResponse[];
  isLoading: boolean;
  hasError: boolean;
  chartsData?: IComposedChartData[];
  onErrorRetry: () => void;
};

const inStoreVolumeChartColors: ChartColors = {
  colors: {
    lineChartOnePrimaryColor: '#FF3C6E',
    lineChartTwoPrimaryColor: '#FF3CFF',
    barChartOnePrimaryColor: '#2cb5cb',
  },
  gradients: {
    lineChartOneGradient: '#fe3bff',
    lineChartTwoGradient: '#f33e75',
  },
};

export const InStoreVolumeCharts: React.FC<Props> = ({
  isLoading,
  hasError,
  navigationItemsData,
  chartsData,
  onErrorRetry,
}) => {
  const [selected, setSelected] = useState<string>();
  return (
    <ThemeProviderContainer>
      <ComposedChartContainer>
        <ChartTopBar>
          <ChartNavigation>
            {navigationItemsData?.map((navItemData) => (
              <ChartNavigationItem
                key={navItemData.label}
                label={navItemData.label}
                thisPeriodValue={navItemData.thisPeriodValue}
                loading={isLoading}
                shape={navItemData.shape}
                valueType={navItemData.valueType || 'number'}
                color={navItemData.color}
                fullWidth={false}
                selected={selected === navItemData.label}
                onClick={setSelected}
              />
            ))}
          </ChartNavigation>
        </ChartTopBar>
        <ComposedChart
          tooltipTitle="In-store payments"
          chartColors={inStoreVolumeChartColors}
          data={chartsData}
          isLoading={isLoading}
          error={hasError}
          onErrorRetry={onErrorRetry}
          selected={selected}
        />
      </ComposedChartContainer>
    </ThemeProviderContainer>
  );
};
