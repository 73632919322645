import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalTrigger,
  TextInput,
} from '@liven-engineering/liven-react-lib';
import { Select, SelectItem } from '@liven-engineering/liven-react-lib';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAppStore } from 'app-store';
import { remoteConfig } from 'config/remote';
import { useAnalytics } from 'config/segment';
import { Form, Formik } from 'formik';
import { useRemoteConfigValue } from 'hooks/useRemoteConfigValue';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { login } from 'services/authentication/login';
import { validateLogin } from 'services/authentication/validateLogin';
import endpoints from 'services/endpoints';
import { setupGlobalAxiosDefaults } from 'services/settings/globalAxiosSettings';
import { IError, UserInfo } from 'types/types';
import { identifyUser } from 'utils/analytics';
import { isGlobalEnabled } from 'utils/build-flags';
import { Regions } from 'utils/constants';
import { setCookie } from 'utils/cookies';
import * as Yup from 'yup';

const updateSchema = Yup.object().shape({
  email: Yup.string().required('Email cannot be empty'),
  password: Yup.string().required('Password cannot be empty'),
  region: Yup.string().oneOf(Object.keys(Regions)),
});

export const LoginForm = () => {
  const registrationEnabled = useRemoteConfigValue<boolean>(
    'is_new_registration_enabled',
    remoteConfig.defaultConfig['is_new_registration_enabled'] as boolean,
  );
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const setUserInfo = useAppStore((state) => state.setUserInfo);
  const [error, setError] = useState<string | null>();
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const setUserAndNavigate = (data: UserInfo) => {
    setUserInfo(data);
    if (analytics) {
      identifyUser(analytics, data);
    }
    navigate('/');
  };
  useQuery(
    ['validate-user'],
    () => {
      return validateLogin().then((data) => {
        setUserAndNavigate(data);
      });
    },
    { retry: false },
  );
  const { mutate, isLoading, isError } = useMutation(
    ({ email, password }: { email: string; password: string }) => login(email, password),
    {
      onSuccess: (data) => {
        setUserAndNavigate(data);
      },
      onError: (error: IError) => {
        if (error.response.data.message) {
          setError(error.response.data.message);
        } else {
          setError('Sorry, something went wrong. Please try again later.');
        }
      },
    },
  );

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        region: Regions.AU,
      }}
      onSubmit={(values) => {
        setCookie('region', values.region);
        setupGlobalAxiosDefaults(values.region as Regions);
        mutate({ email: values.email, password: values.password });
      }}
      validationSchema={updateSchema}
    >
      {(props) => (
        <div className="mx-auto my-auto sm:mx-auto sm:w-full sm:max-w-xl">
          <div className="bg-liven-gray1 border-[1px] border-liven-gray7 py-8 px-4 sm:rounded-lg sm:px-10">
            <Form className="space-y-6" onSubmit={props.handleSubmit}>
              <h1 className="text-center text-3xl font-medium">Sign In</h1>
              <p className="text-center text-base font-medium text-gray-600 !mt-2.5">
                Sign in to your Liven Dashboard.
              </p>
              <div className="mt-1">
                <TextInput
                  name="email"
                  type="email"
                  required={true}
                  placeholder="Enter your email"
                  value={props.values.email}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                />
              </div>
              <div className="mt-1">
                <TextInput
                  name="password"
                  type="password"
                  required={true}
                  placeholder="Enter your password"
                  value={props.values.password}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                />
              </div>
              {isGlobalEnabled() && (
                <div className="mt-1">
                  <Select
                    name="region"
                    value={props.values.region}
                    onValueChange={(value) => props.handleChange({ target: { name: 'region', value } })}
                  >
                    <SelectItem value={Regions.AU}>
                      <img src="/au.png" />
                      Australia
                    </SelectItem>
                    <SelectItem value={Regions.US}>
                      <img src="/us.png" />
                      United States
                    </SelectItem>
                    <SelectItem value={Regions.SG}>
                      <img src="sg.png" />
                      Singapore
                    </SelectItem>
                  </Select>
                </div>
              )}
              {!!props.touched.email && !!props.errors.email && (
                <div className="w-full flex justify-center text-sm text-rose-500">
                  <p>{props.errors.email}</p>
                </div>
              )}
              {!!props.touched.password && !!props.errors.password && (
                <div className="w-full flex justify-center text-sm text-rose-500">
                  <p>{props.errors.password}</p>
                </div>
              )}
              {!!props.errors.region && (
                <div className="w-full flex justify-center text-sm text-rose-500">
                  <p>{props.errors.region}</p>
                </div>
              )}
              {isError && (
                <div className="w-full flex justify-center text-sm text-rose-500">
                  <p>{error}</p>
                </div>
              )}

              <button
                type="submit"
                value="SIGN IN"
                disabled={isLoading}
                className="w-28 flex justify-center py-2 px-4 border border-transparent rounded-full text-base font-semibold text-white bg-rose-500 hover:bg-rose-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500  ml-auto mr-auto disabled:bg-gray-300"
              >
                Sign in
              </button>
              <div className="w-full flex justify-center">
                <span
                  onClick={() => setOpenConfirmationModal(true)}
                  className="font-normal text-sm leading-5 hover:text-liven-pink cursor-pointer"
                >
                  Forgotten Password?
                </span>
              </div>
              {registrationEnabled && (
                <div className="w-full flex justify-center text-base">
                  <p>
                    Don't have an account?
                    <Link to="register" className="sm:mx-auto font-medium text-base hover:text-liven-pink">
                      Join Now
                    </Link>
                  </p>
                </div>
              )}
            </Form>
            <Modal open={openConfirmationModal} onOpenChange={setOpenConfirmationModal}>
              <ModalTrigger />
              <ModalContent>
                <ModalHeader className="!px-6 !tracking-normal">Forgotten Password?</ModalHeader>
                <ModalBody className="!w-[350px] !max-w-[350px] text-center tracking-normal">
                  <h4>What region are you based out of?</h4>
                  <div className="flex flex-col gap-4 mt-4 text-sm">
                    <a
                      href={`${process.env[`REACT_APP_SG1_API_PREFIX_AU`]}${endpoints.authentication.RESET_PASSWORD}`}
                      target="_blank"
                      rel="noreferrer"
                      className="w-full py-2 flex gap-1 justify-center items-center px-4 border border-transparent rounded-lg text-base font-semibold text-white bg-rose-500 hover:bg-rose-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500  ml-auto mr-auto disabled:bg-gray-300"
                      onClick={() => setOpenConfirmationModal(false)}
                    >
                      Australia
                    </a>
                    <a
                      href={`${process.env[`REACT_APP_SG1_API_PREFIX_US`]}${endpoints.authentication.RESET_PASSWORD}`}
                      target="_blank"
                      rel="noreferrer"
                      className="w-full py-2 flex gap-1 justify-center items-center px-4 border border-transparent rounded-lg text-base font-semibold text-white bg-rose-500 hover:bg-rose-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500  ml-auto mr-auto disabled:bg-gray-300"
                      onClick={() => setOpenConfirmationModal(false)}
                    >
                      United States
                    </a>
                    <a
                      href={`${process.env[`REACT_APP_SG1_API_PREFIX_SG`]}${endpoints.authentication.RESET_PASSWORD}`}
                      target="_blank"
                      rel="noreferrer"
                      className="w-full py-2 flex gap-1 justify-center items-center px-4 border border-transparent rounded-lg text-base font-semibold text-white bg-rose-500 hover:bg-rose-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500  ml-auto mr-auto disabled:bg-gray-300"
                      onClick={() => setOpenConfirmationModal(false)}
                    >
                      Singapore
                    </a>
                  </div>
                </ModalBody>
              </ModalContent>
            </Modal>
          </div>
        </div>
      )}
    </Formik>
  );
};
