import { AnalyticsBrowser } from '@segment/analytics-next';
import { UserInfo } from 'types/types';

export const identifyUser = (analytics: AnalyticsBrowser, { userId, email, firstName, lastName }: UserInfo) => {
  analytics.identify(userId.toString(), {
    firstName: firstName,
    lastName: lastName,
    email: email,
    id: userId,
  });
};
