import styles from 'components/charts/chart-navigation/chart-navigation-item.module.css';
import { SkeletonLoader } from 'components/charts/skeleton/skeleton-loader';
import { TrendBadge } from 'components/charts/trends/trend-badge';
import { motion } from 'framer-motion';

interface IChartNavigationItemProps {
  label: string;
  thisPeriodValue: string;
  lastPeriodValue?: string;
  color?: string;
  shape?: 'bar' | 'line';
  loading: boolean;
  valueType?: 'dollar' | 'number';
  selected?: boolean;
  fullWidth?: boolean;
  onClick?: (selected: string) => void;
  style?: React.CSSProperties;
}

export const ChartNavigationItem: React.FC<IChartNavigationItemProps> = ({
  label,
  thisPeriodValue,
  lastPeriodValue,
  color,
  shape,
  loading,
  valueType,
  fullWidth,
  selected,
  onClick,
}) => {
  const metricValue = Intl.NumberFormat().format(parseInt(thisPeriodValue));
  return (
    <div
      className={`${styles.chartNavButtonWrapper} ${fullWidth && styles.chartNavButtonFullWidth}`}
      onClick={() => {
        if (!selected) {
          onClick?.('empty');
          setTimeout(() => {
            onClick?.(label);
          });
        } else {
          onClick?.('empty');
          setTimeout(() => {
            onClick?.('');
          });
        }
      }}
    >
      <div className={`${styles.chartNavButton} ${selected && styles.chartNavButtonSelected} `}>
        <div className={styles.labelContainer}>
          {!loading && color && (
            <div
              className={shape === 'bar' ? styles.legendSquare : styles.legendLine}
              style={{ background: color, display: 'inline' }}
            />
          )}
          <p className={styles.chartNavLabel}>{label}</p>
        </div>
        <div className={styles.metricContainer}>
          <div className={styles.chartNavMetric}>
            {valueType === 'dollar' && !loading ? <span>$</span> : null}
            {loading ? <SkeletonLoader /> : metricValue}
          </div>
          {!loading && lastPeriodValue && (
            <TrendBadge thisPeriodValue={parseFloat(thisPeriodValue)} lastPeriodValue={parseFloat(lastPeriodValue)} />
          )}
        </div>
      </div>
      {selected != null && selected ? (
        <motion.div className={styles.chartNavButtonUnderline} layoutId="chartNavButtonUnderline" />
      ) : null}
    </div>
  );
};
