import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppStore } from 'app-store';
import { BarsLoader } from 'components/animation/loader/bars-loader';
import { GenericError } from 'components/common/generic-error';
import Navbar from 'components/navbar/navbar';
import { useAnalytics } from 'config/segment';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { loadEntities } from 'services/entities';
import { SelectedEntity } from 'types/types';
import { isRecordEmpty } from 'utils/utils';
import { BRANCH_GROUPS, MERCHANTS } from 'utils/constants';
import Container from 'views/routes/container/container';

export const EntityLoader = () => {
  const analytics = useAnalytics();
  const queryClient = useQueryClient();
  const { merchants, branchGroups } = useAppStore((state) => state.entities);
  const setSelectEntity = useAppStore((state) => state.setSelectEntity);
  const { entityType, entityId } = useParams();
  const navigate = useNavigate();
  const setEntities = useAppStore((state) => state.setEntities);
  const { isLoading, isError, data: entities } = useQuery(['entities'], () => loadEntities());

  useEffect(() => {
    if (entities?.branchGroups || entities?.merchants) {
      const merchantsRecord = entities?.merchants;
      const branchGroupsRecord = entities?.branchGroups;
      setEntities(entities);
      let selectedEntity: SelectedEntity | undefined;

      if (entityId && entityType === MERCHANTS && merchantsRecord && merchantsRecord[entityId]) {
        selectedEntity = {
          id: entityId,
          type: MERCHANTS,
        };
      } else if (entityId && entityType === BRANCH_GROUPS && branchGroupsRecord && branchGroupsRecord[entityId]) {
        selectedEntity = {
          id: entityId,
          type: BRANCH_GROUPS,
        };
      } else if (merchantsRecord && !isRecordEmpty(merchantsRecord)) {
        selectedEntity = {
          id: Object.keys(merchantsRecord)[0],
          type: MERCHANTS,
        };
      } else if (branchGroupsRecord && !isRecordEmpty(branchGroupsRecord)) {
        selectedEntity = {
          id: Object.keys(branchGroupsRecord)[0],
          type: BRANCH_GROUPS,
        };
      }
      if (selectedEntity) {
        setSelectEntity(selectedEntity);
        const { id, type } = selectedEntity;
        analytics.track('Viewed Initial Entity', {
          entity_id: id,
          entity_name:
            type === MERCHANTS
              ? merchants && merchants[id].merchantName
              : branchGroups && branchGroups[id].branchGroupName,
          entity_type: type,
        });
        if (!entityId || !entityType) navigate(`${type}/${id}`);
      }
    }
  }, [entityId, entityType, entities, analytics]);

  return (
    <>
      {isLoading && <BarsLoader />}
      {!isLoading && (merchants || branchGroups) && (
        <>
          <Navbar />
          <Container />
        </>
      )}
      {isError && <GenericError handleRetry={() => queryClient.resetQueries({ queryKey: ['entities'] })} />}
    </>
  );
};
