type Props = {
  retryStatements: () => void;
};

export const RetryStatements = ({ retryStatements }: Props) => {
  return (
    <button
      type="submit"
      value="SIGN IN"
      className="w-28 flex justify-center py-2 px-4 border border-transparent rounded-full text-base font-semibold text-white bg-rose-500 hover:bg-rose-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500  ml-auto mr-auto disabled:bg-gray-300"
      onClick={() => retryStatements()}
    >
      RETRY
    </button>
  );
};
