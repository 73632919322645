import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AnalyticsDisclaimer } from 'components/charts/analytics-disclaimer';
import { ChartNavigationItem } from 'components/charts/chart-navigation/chart-navigation-item';
import { ChartNavigation, ChartTopBar, ComposedChart, ComposedChartContainer } from 'components/charts/composed-chart';
import { TabGroup } from 'components/tabGroup/TabGroup';
import { ThemeProviderContainer } from 'components/theme/theme-provider-container';
import { useAnalytics } from 'config/segment';
import { transformChartNavigationAnalyticsData, transformDollarsAnalyticsData } from 'lib/data-transformers';
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation, useParams } from 'react-router-dom';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { AnalyticsDataRequestParams, fetchDollarsAnalyticsData } from 'services/merchant/analytics/analytics-service';
import { ChartColors, IChartDataRequestParams, INavItemsRequestParams, TimeInterval } from 'types/types';
import { isAnalytics2Enabled } from 'utils/build-flags';
import { AnalyticsTabs, BRANCH_GROUPS, DEFAULT_INTERVAL, DEFAULT_PERIOD, MERCHANTS } from 'utils/constants';
import { BRANCH_GROUP_BRAND_DOLLARS_SET, MERCHANT_BRAND_DOLLARS_SET } from 'utils/metrics-sets';
import { getTimeZone } from 'utils/utils';

import { getStartAndEndDate } from './analytics-utils';
import { BrandollarsHeatmap } from './brandollar-heatmap';
import { BrandollarsBreakdown } from './brandollars-breakdown';
import { FilterContainer } from './filter-container';

export const AnalyticsContainer = () => {
  const DEFAULT_RANGE_FOR_DATA_IN_DAYS = '84';
  const { entityId, merchantId, entityType } = useParams();
  const [tab, setTab] = useState('Overview');
  const divElement = useRef<HTMLDivElement>(null);

  if (!entityId) {
    throw new Error('AnalyticsContainer entityId is required');
  }

  const options = {
    root: divElement.current,
    threshold: 0.5,
  };
  const { ref: overviewRef } = useInView({
    ...options,
    onChange: (inView) => {
      if (inView) setTab('Overview');
    },
  });
  const { ref: breakdownRef } = useInView({
    ...options,
    onChange: (inView) => {
      if (inView) setTab('Breakdown');
    },
  });
  const [period, setPeriod] = useState(DEFAULT_RANGE_FOR_DATA_IN_DAYS);
  const [interval, setTimeInterval] = useState<TimeInterval>('week');
  const [range, setRange] = useState<null | DateRange>(null);
  const queryClient = useQueryClient();
  const { startDate, endDate } = getStartAndEndDate(period, range);
  const analytics = useAnalytics();
  const { pathname } = useLocation();
  const [selected, setSelected] = useState<string>();

  const chartColors: ChartColors = {
    colors: {
      lineChartOnePrimaryColor: '#FF3C6E',
      lineChartTwoPrimaryColor: '#FF3CFF',
      barChartOnePrimaryColor: '#2cb5cb',
    },
    gradients: {
      lineChartOneGradient: '#fe3bff',
      lineChartTwoGradient: '#f33e75',
    },
  };

  const getBaseAnalyticsRequestParams = (entityId: string, entityType?: string) => {
    const params = {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      timezone: getTimeZone(),
    };

    if (entityType === undefined || entityType === MERCHANTS) {
      const navigationItemParams: INavItemsRequestParams = {
        ...params,
        dimensions: 'merchant_id',
        merchant_id: entityId,
        metric_names: `voucher_total_sales_${entityId},voucher_total_credits_${entityId},voucher_counts_${entityId}`,
        voucher_type: 'merchant_voucher',
      };

      const chartsDataParams: IChartDataRequestParams = {
        ...navigationItemParams,
        interval,
      };

      return { navigationItemParams, chartsDataParams };
    }

    const navigationItemParams: INavItemsRequestParams = {
      ...params,
      dimensions: 'branch_group_id',
      branch_group_id: entityId,
      metric_names: 'total_cash,total_count,total_credit',
    };

    const chartsDataParams: IChartDataRequestParams = {
      ...navigationItemParams,
      interval,
    };

    return { navigationItemParams, chartsDataParams };
  };

  const analyticsDataRequestParams: AnalyticsDataRequestParams = {
    metricsSet: entityType === BRANCH_GROUPS ? BRANCH_GROUP_BRAND_DOLLARS_SET : MERCHANT_BRAND_DOLLARS_SET,
    ...getBaseAnalyticsRequestParams(entityId, entityType),
  };
  const {
    isLoading,
    isError,
    data: analyticsData,
  } = useQuery(
    ['analytics-dollars', analyticsDataRequestParams],
    () =>
      fetchDollarsAnalyticsData(analyticsDataRequestParams).then((data) => {
        return {
          navigationItemResponse: transformChartNavigationAnalyticsData(data[0]),
          chartDataResponse: transformDollarsAnalyticsData(data[1], interval),
        };
      }),
    {
      staleTime: 60000,
    },
  );

  useEffect(() => {
    analytics.track('Viewed Analytics Brandollars Sales', {
      entity_id: entityId,
      entity_type: entityType,
      interval,
      period,
      path: pathname,
      screen: 'Brandollars Sales',
    });
  }, [entityId, merchantId, pathname, analytics, entityType, interval, period, range]);

  return (
    <ThemeProviderContainer>
      <div
        ref={divElement}
        className="flex w-full overflow-x-hidden h-[calc(100vh-3.5rem)] overflow-y-scroll bg-liven-gray1"
      >
        <div className="flex flex-col w-full h-fit relative">
          <div className="pt-8 bg-liven-gray1">
            <h1 className="w-full mx-auto max-w-5xl text-3xl font-medium ">Brandollars</h1>
          </div>
          <div className="border-b w-full border-b-1 border-solid border-liven-gray5 top-0 sticky z-10 flex bg-liven-gray1">
            <div className="mx-auto w-full max-w-5xl flex">
              <TabGroup
                tabs={
                  isAnalytics2Enabled() ? [AnalyticsTabs.OVERVIEW, AnalyticsTabs.BREAKDOWN] : [AnalyticsTabs.OVERVIEW]
                }
                active={tab}
                onTabClick={(tab) => {
                  setTab(tab);
                }}
                contentContainerRef={divElement}
              />
              <div className="ml-auto">
                <FilterContainer
                  disabledBranchFilter={true}
                  className="flex flex-row p-2 justify-end"
                  showAnalyticsFilters={true}
                  showBranchFilter={false}
                  enableAllBranchesOption={true}
                  data-testid="filter-container"
                  handleFilterChanged={(filter) => {
                    if (filter.period === 'custom' && !filter.range) return;
                    setPeriod(filter.period ?? DEFAULT_PERIOD);
                    setTimeInterval(filter.interval ?? DEFAULT_INTERVAL);
                    if (filter.range) setRange(filter.range);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="bg-liven-gray2 pb-10 relative ">
            <div className="flex flex-col mx-auto max-w-5xl pt-6 gap-6" data-testid="analytics-brandollars">
              <div ref={overviewRef}>
                <h2 id={AnalyticsTabs.OVERVIEW} className="text-2xl pt-6 font-medium">
                  {AnalyticsTabs.OVERVIEW}
                </h2>
              </div>

              <ComposedChartContainer>
                <ChartTopBar>
                  <ChartNavigation>
                    {analyticsData?.navigationItemResponse?.map((navItemData) => (
                      <ChartNavigationItem
                        key={navItemData.label}
                        label={navItemData.label}
                        thisPeriodValue={navItemData.thisPeriodValue}
                        loading={isLoading}
                        shape={navItemData.shape}
                        valueType={navItemData.valueType ? navItemData.valueType : 'number'}
                        color={navItemData.color}
                        fullWidth
                        selected={selected === navItemData.label}
                        onClick={setSelected}
                      />
                    ))}
                  </ChartNavigation>
                </ChartTopBar>
                <ComposedChart
                  tooltipTitle="Brandollars"
                  chartColors={chartColors}
                  data={analyticsData?.chartDataResponse}
                  isLoading={isLoading}
                  error={isError}
                  onErrorRetry={() => {
                    queryClient.invalidateQueries({ queryKey: ['analytics-dollars'] });
                  }}
                  selected={selected}
                />
              </ComposedChartContainer>
              {isAnalytics2Enabled() && (
                <>
                  <h2 id={AnalyticsTabs.BREAKDOWN} className="text-2xl pt-6 font-medium">
                    {AnalyticsTabs.BREAKDOWN}
                  </h2>
                  <div ref={breakdownRef}>
                    {entityType === MERCHANTS && (
                      <BrandollarsBreakdown period={period} interval={interval} range={range} />
                    )}
                  </div>
                  <BrandollarsHeatmap period={period} range={range} />
                </>
              )}

              <AnalyticsDisclaimer />
            </div>
          </div>
        </div>
      </div>
    </ThemeProviderContainer>
  );
};
