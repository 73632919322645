import { useAppStore } from 'app-store';
import moment from 'moment';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { EntityType, IAnalyticsRequestParams } from 'types/types';
import { MERCHANTS } from 'utils/constants';
import { getBranchIdsFromEntity } from 'utils/entities';

export const getBaseAnalyticsRequestParams = (
  startDate: moment.Moment,
  endDate: moment.Moment,
  metricNames: string,
  entityId: string,
  timezone: string,
  entityType: EntityType,
  branchId?: string,
  brandollarIds?: string,
  dimensions?: string,
  voucher_type?: 'branch_group_voucher' | 'merchant_voucher',
): IAnalyticsRequestParams => {
  const { branchGroups, branches, merchants } = useAppStore.getState().entities;
  const params: Omit<IAnalyticsRequestParams, 'merchant_id'> = {
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD'),
    metric_names: metricNames,
    timezone: timezone,
    ...(brandollarIds ? { voucher_id: brandollarIds } : {}),
    ...(dimensions ? { dimensions } : {}),
    ...(voucher_type ? { voucher_type } : {}),
  };

  if (entityType === MERCHANTS) {
    if (branchId && branchId !== 'all') {
      return {
        ...params,
        merchant_id: entityId,
        branch_id: branchId,
      };
    }
    if (!merchants) {
      throw new Error('AnalyticsUtils - merchants is undefined');
    }
    if (!branches) {
      throw new Error('AnalyticsUtils - branches is undefined');
    }

    return {
      ...params,
      merchant_id: entityId,
      ...(!voucher_type ? { branch_id: getBranchIdsFromEntity(merchants[entityId].branchIds, branches) } : {}),
    };
  } else {
    if (branchId && branchId !== 'all') {
      if (!branches) {
        throw new Error('AnalyticsUtils - branches is undefined');
      }

      return {
        ...params,
        branch_id: branchId,
      };
    } else if (branchId && branchId === 'all') {
      if (!branchGroups) {
        throw new Error('AnalyticsUtils - branchGroups is undefined');
      }

      return {
        ...params,
        branch_id: branchGroups[entityId].branchIds.join(','),
      };
    } else {
      return {
        branch_group_id: entityId,
        ...params,
      };
    }
  }
};

export const formatChartSubmissionDate = (date: Date) => {
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

export const getBranchGroupMeticName = (metric: string) => {
  switch (metric) {
    case 'voucher_total_sales':
      return 'total_cash';
    case 'voucher_total_credits':
      return 'total_credits';
    default:
      return 'total_count';
  }
};

export const getStartAndEndDate = (period: string, range?: DateRange | null) => {
  let endDate = moment();
  let startDate = moment();
  if (period !== 'custom' && period !== 'insight') startDate = moment(endDate.format()).subtract(period, 'days');
  else if (period === 'insight') {
    endDate = moment(endDate.format()).subtract(1, 'days');
    startDate = moment(endDate.format()).subtract(28, 'days');
  } else {
    if (range?.[0] && range?.[1]) {
      startDate = moment(range?.[0]);
      endDate = moment(range?.[1]);
    }
  }
  return { endDate, startDate };
};
