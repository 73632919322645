import styles from 'components/charts/trends/trend-badge.module.css';
import { TrendDown, TrendUp } from 'phosphor-react';

export const TrendBadge: React.FC<{
  thisPeriodValue: number;
  lastPeriodValue: number;
  size?: 'sm';
}> = ({ thisPeriodValue, lastPeriodValue, size }) => {
  const lift = ((thisPeriodValue - lastPeriodValue) / lastPeriodValue) * 100;
  const formattedFloatLift = parseFloat(lift.toFixed(0));
  const formattedLift = `${formattedFloatLift}%`;
  if (!isFinite(lift) || isNaN(lift)) {
    return null;
  }
  console.log('lift', lift);

  return (
    <div
      className={`${styles.trendBadge} ${lift > 0 ? styles.greenBadge : styles.redBadge} ${
        size === 'sm' && styles.smallTrendBadge
      }`}
    >
      {lift > 0 ? <TrendUp weight="bold" /> : <TrendDown weight="bold" />}
      {formattedLift}
    </div>
  );
};
