// Import the functions you need from the SDKs you need
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { initializeApp } from 'firebase/app';
import { getRemoteConfig } from 'firebase/remote-config';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const firebaseMerchantApp = initializeApp(firebaseConfig);

export const remoteConfig = getRemoteConfig(firebaseMerchantApp);

// This setting is only for development environment to reflect flag changes and avoid caching.
// For any other environment we will use the default settings to avoid throttling errors.
if (process.env.NODE_ENV === 'development') {
  remoteConfig.settings.minimumFetchIntervalMillis = 360000;
}

remoteConfig.defaultConfig = {
  is_profile_enabled: true,
  is_feedback_enabled: true,
  is_manage_info_enabled: true,
  is_team_enabled: false,
  is_push_service_enabled: false,
  is_new_registration_enabled: false,
  is_request_foodollar_changes_enabled: false,
};

// Datadog Logs
if (process.env.REACT_APP_DATADOG_ENABLED === 'true') {
  if (!process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
    console.log('Datadog logs are enabled but the client token is not set');
  } else {
    datadogLogs.init({
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN!,
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sampleRate: 100,
      service: 'merchant-portal',
      env: process.env.REACT_APP_ENV,
    });
  }
}

// Datadog RUM (Real User Monitoring) initialisation
if (process.env.REACT_APP_DATADOG_RUM_ENABLED === 'true') {
  if (!process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID || !process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN) {
    console.log('Datadog RUM is enabled but the application id or client token is not set');
  } else {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID!,
      clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN!,
      site: 'datadoghq.com',
      service: 'merchant-portal',
      env: process.env.REACT_APP_ENV,
      sessionSampleRate: 50,
      sessionReplaySampleRate: 50,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
    datadogRum.startSessionReplayRecording();
  }
}
