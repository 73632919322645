export const isProd = () => process.env.REACT_APP_ENV === 'production';
/* eslint-disable @typescript-eslint/no-unused-vars */
const isStaging = () => process.env.REACT_APP_ENV === 'staging';
/* eslint-disable @typescript-eslint/no-unused-vars */
const isDev = () => process.env.REACT_APP_ENV === 'dev';
/* eslint-disable @typescript-eslint/no-unused-vars */
const isLocal = () => process.env.NODE_ENV === 'development';

export const isBrandollarEnabled = () => true;

export const isGlobalEnabled = () => true;

export const isAnalytics2Enabled = () => true;
