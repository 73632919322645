import {
  Button,
  Collapsible,
  DropdownContent,
  DropdownItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTrigger,
} from '@liven-engineering/liven-react-lib';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppStore } from 'app-store';
import { Voucher } from 'components/Voucher/Voucher';
import { BarsLoader } from 'components/animation/loader/bars-loader';
import { useAnalytics } from 'config/segment';
import moment from 'moment';
import { CircleNotch, Info, Repeat, Trash } from 'phosphor-react';
import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { getBrandDollars, updateBrandDollar } from 'services/merchant/brandollars/brandollars-service';
import { IError, IVoucher } from 'types/types';
import { BRANCH_GROUPS, MERCHANTS } from 'utils/constants';
import SVGS from 'views/Logos/logos';

import { displayTime } from './brandollar-helper';
import { CreateBrandollars } from './create-brandollar';

const days = ['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const buildConditions = (brandollar: IVoucher): string[] => {
  const conditions: string[] = [];
  if (brandollar.startTime) {
    const startDate = new Date(brandollar.startTime);
    if (moment().isBefore(startDate)) conditions.push(`Starts ${startDate.toLocaleDateString()}`);
  }
  if (brandollar.expireTime) {
    const endDate = new Date(brandollar.expireTime);
    if (endDate < new Date('2029-01-01T00:00:00')) {
      conditions.push(`Ends ${endDate.toLocaleDateString()}`);
    }
  }
  if (brandollar.useTime) {
    if (brandollar.useTime.days) {
      conditions.push(`Spend on ${brandollar.useTime.days.map((day) => days[day]).join(', ')}`);
    }
    if (brandollar.useTime.startHour) {
      conditions.push(
        `between ${displayTime(brandollar.useTime.startHour, brandollar.useTime.startMinute)} to ${displayTime(
          brandollar.useTime.endHour,
          brandollar.useTime.endMinute,
        )}`,
      );
    }
  }
  if (brandollar.remaining) {
    conditions.push(`${brandollar.remaining} vouchers remaining`);
  }
  return conditions;
};

const filterAndSort = (vouchers: IVoucher[], isActive: boolean): IVoucher[] => {
  return vouchers
    .filter((brandollar: IVoucher) => {
      if (
        isActive &&
        brandollar.isActive === true &&
        (!brandollar.expireTime || moment(brandollar.expireTime).isAfter(new Date()))
      )
        return true;
      else if (
        !isActive &&
        (brandollar.isActive === false ||
          (brandollar.isActive === true && brandollar.expireTime && moment(brandollar.expireTime).isBefore(new Date())))
      )
        return true;
      else return false;
    })
    .sort((a: IVoucher, b: IVoucher) => a.price - b.price);
};

export const BrandDollarsVouchers = () => {
  const { entityType, entityId } = useParams() as { entityType: string; entityId: string };
  const entities = useAppStore((state) => state.entities);
  const selectedEntityData = useAppStore((state) => state.selectedEntityData());
  const analytics = useAnalytics();

  const { merchants, branchGroups } = entities;
  const queryClient = useQueryClient();
  const [selectedBrandollar, setSelectedBrandollar] = useState<IVoucher | null>(null);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [openRecoverModal, setOpenRecoverModal] = useState(false);
  const [error, setError] = useState<string | null>();

  const { isLoading, data: brandollars } = useQuery(['brandollars', entityType, entityId], () =>
    getBrandDollars(entityId, entityType).then((data) => {
      const activeBrandollars = filterAndSort(data, true);
      const archivedBrandollars = filterAndSort(data, false);
      const expiredIds = archivedBrandollars
        .filter((voucher) => {
          return voucher.isActive;
        })
        .map((voucher) => voucher.id);
      if (expiredIds && expiredIds.length > 0) expireBrandollars(expiredIds);
      return {
        activeBrandollars,
        archivedBrandollars,
      };
    }),
  );
  const { mutate, isLoading: updateLoading } = useMutation(
    ({ voucherID, isActive }: { voucherID: number; isActive: boolean }) => updateBrandDollar(voucherID, isActive),
    {
      onSuccess: (data, variables) => {
        analytics.track(variables.isActive ? 'Brandollar Restored' : 'Brandollar Archived', {
          entity_type: entityType,
          entity_id: entityId,
          brandollar_id: data.id,
        });

        queryClient.resetQueries({ queryKey: ['brandollars'] });
        setOpenArchiveModal(false);
        setOpenRecoverModal(false);
      },
      onError: (error: IError) => {
        if (error.response.data.detail) {
          setError(error.response.data.detail);
        } else {
          setError('Sorry, something went wrong. Please try again later.');
        }
      },
    },
  );

  const getBrandName = () => {
    if (entityType === MERCHANTS && merchants && entityId) return merchants[entityId].merchantName;
    else if (entityType === BRANCH_GROUPS && branchGroups && entityId) return branchGroups[entityId].branchGroupName;
    else return '';
  };

  const archiveBrandollar = () => {
    if (selectedBrandollar) {
      mutate({ voucherID: selectedBrandollar.id, isActive: false });
    }
  };
  const recoverBrandollar = () => {
    if (selectedBrandollar) {
      mutate({ voucherID: selectedBrandollar.id, isActive: true });
    }
  };

  const expireBrandollars = (ids: number[]) => {
    ids.forEach((id) => expireBrandollar({ voucherID: id, isActive: false }));
  };

  const { mutate: expireBrandollar } = useMutation(
    ({ voucherID, isActive }: { voucherID: number; isActive: boolean }) => updateBrandDollar(voucherID, isActive),
    {
      onSuccess: (data, variables) => {
        analytics.track('Auto Brandollar Archived', {
          entity_type: entityType,
          entity_id: entityId,
          brandollar_id: data.id,
        });
      },
      onError: (error: IError) => {
        console.log('Sorry, something went wrong in auto archiving.');
      },
    },
  );

  if (!selectedEntityData?.brandollarsEditAllowed) {
    return <Navigate to={`../analytics/instore-volume`} replace={true} />;
  }

  return (
    <div className="flex flex-col py-8 gap-6 px-5 text-center h-full  overflow-y-auto border-l border-l-1 border-liven-gray6 border-solid bg-liven-gray1">
      <h2 className="text-2xl font-medium pt-6 flex flex-row items-center gap-2 justify-center">
        <SVGS.Live /> Live
      </h2>
      <p className="text-base font-normal text-liven-gray11">
        These are your Brandollar packages currently for sale, across
        <span className="font-medium text-liven-gray12"> all {getBrandName()} stores.</span>
      </p>
      <div className=" p-4 flex flex-col gap-3">
        <CreateBrandollars />
        {isLoading && <BarsLoader />}
        {!isLoading &&
          brandollars &&
          brandollars.activeBrandollars &&
          brandollars.activeBrandollars.map((brandollar: IVoucher) => (
            <Voucher
              key={`foodollar-pack-${brandollar.id}`}
              buyAmount={brandollar.price}
              creditAmount={brandollar.credit}
              firstTimeOnly={brandollar.userCap === 1}
              conditions={buildConditions(brandollar)}
              dropdownContent={
                <DropdownContent>
                  <DropdownItem
                    danger
                    icon={<Trash />}
                    onSelect={(event) => {
                      event.preventDefault();
                      setSelectedBrandollar(brandollar);
                      setError(null);
                      setOpenArchiveModal(true);
                    }}
                  >
                    Archive
                  </DropdownItem>
                </DropdownContent>
              }
            />
          ))}
      </div>
      {!isLoading && brandollars && brandollars.archivedBrandollars && (
        <Collapsible header="Archived Brandollars">
          <div className="flex flex-col gap-3" data-testid="archived-brandollars">
            {brandollars.archivedBrandollars.map((brandollar: IVoucher) => (
              <Voucher
                key={`foodollar-pack-${brandollar.id}`}
                buyAmount={brandollar.price}
                creditAmount={brandollar.credit}
                firstTimeOnly={brandollar.userCap === 1}
                conditions={buildConditions(brandollar)}
                dropdownContent={
                  <DropdownContent>
                    <DropdownItem
                      icon={<Repeat weight="bold" />}
                      onSelect={(event) => {
                        event.preventDefault();
                        setSelectedBrandollar(brandollar);
                        setError(null);
                        setOpenRecoverModal(true);
                      }}
                    >
                      Recover
                    </DropdownItem>
                  </DropdownContent>
                }
              />
            ))}
          </div>
        </Collapsible>
      )}
      <Modal open={openArchiveModal} onOpenChange={setOpenArchiveModal}>
        <ModalTrigger />
        <ModalContent>
          <ModalHeader displayCloseButton>
            <Info />
            Are you sure?
          </ModalHeader>
          <ModalBody>
            Are you sure you want to pause this Brandollar package?
            <br /> Customers will no longer be able to purchase this package,
            <br /> but any packages already purchased can still be redeemed.
            <br />
            <br />
            {error && (
              <>
                <div className=" text-liven-error11">{error}</div> <br />
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button type="button" disabled={updateLoading} onClick={archiveBrandollar} variant="destructive">
              {updateLoading && <CircleNotch className="animate-spin" weight="bold" />} Yes, archive
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal open={openRecoverModal} onOpenChange={setOpenRecoverModal}>
        <ModalTrigger />
        <ModalContent>
          <ModalHeader displayCloseButton>
            <Info />
            Are you sure?
          </ModalHeader>
          <ModalBody>
            Are you sure you want to restore this Brandollar package?
            <br /> Customers will be able to purchase this package once recovered.
            <br />
            <br />
            {error && (
              <>
                <div className=" text-liven-error11">{error}</div> <br />
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button type="button" disabled={updateLoading} onClick={recoverBrandollar} variant="destructive">
              {updateLoading && <CircleNotch className="animate-spin" weight="bold" />}
              Yes, recover
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
