import axios from 'axios';
import endpoints from 'services/endpoints';
import { IVoucher } from 'types/types';

export const getBrandDollars = (entityId: string, entityType: string): Promise<IVoucher[]> => {
  // TODO: Clean up different naming of branch groups
  const requestEntityType = entityType === 'branchgroups' ? 'branch_groups' : entityType
  return axios.get(endpoints.brandollars.vouchers(requestEntityType, entityId)).then((response) => response.data);
};

export const updateBrandDollar = (voucherId: number, isActive: boolean): Promise<IVoucher> => {
  return axios.patch(endpoints.brandollars.update(voucherId), { isActive }).then((response) => response.data);
};

export interface IUseTimeProps {
  days?: number[];
  start_time?: string;
  end_time?: string;
}
export interface IVoucherProps {
  entity_type: string;
  entity_id: number;
  credit: number;
  price: number;
  remaining: number | null;
  start_time?: string;
  expire_time?: string;
  user_cap: number | null;
  use_time?: IUseTimeProps;
}

export const createBrandDollar = (voucherProps: IVoucherProps): Promise<IVoucher> => {
  return axios.post(endpoints.brandollars.create(), voucherProps).then((response) => response.data);
};
