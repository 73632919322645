import authenticationService from 'services/authentication/authentication-service';
import entitiesService from 'services/entities';
import merchantService from 'services/merchant/merchant-service';
import settings from 'services/settings/settings-service';

const services = {
  authenticationService,
  entitiesService,
  merchantService,
  settings,
};

export default services;
