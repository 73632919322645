import { Outlet } from 'react-router-dom';

const Container = () => {
  return (
    <div className="min-h-full flex flex-auto pt-[57px]">
      {/* Static sidebar for desktop */}
      <div className="flex flex-shrink-0 w-full overflow-hidden">
        <Outlet />
      </div>
    </div>
  );
};

export default Container;
