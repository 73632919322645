import React from 'react';

import styles from './loader.module.css';

export const ChartLoader: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.loadingContainer}>
        <div className={styles.shinyBoxContainer}>
          <div className={styles.shinyBox}>Loading...</div>
        </div>
      </div>
    </div>
  );
};
