const url =
  process.env.REACT_APP_FEEDBACK_CLICKUP_EMBEDED ||
  'https://forms.clickup.com/2700544/f/2jd80-238642/9PMW0XU79AQ9DS8RTA';

export const Feedback = () => (
  <>
    <iframe
      data-testid="click-up-form"
      title="Merchant Portal Feedback"
      className="clickup-embed clickup-dynamic-height"
      src={url}
      width="100%"
      height="100%"
    ></iframe>
  </>
);
