import { Authenticated } from 'views/routes/authenticated';
import { EntityLoader } from 'views/routes/entity-loader';

export const AppContainer = () => {
  return (
    <Authenticated>
      <EntityLoader />
    </Authenticated>
  );
};
