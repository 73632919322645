import { useAppStore } from 'app-store';
import { SidebarContainer } from 'components/sidebar/sidebar-container';
import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { isSelectedEntityValid } from 'utils/entities';

export const EmptyContainer = () => {
  const { entityType, entityId } = useParams();
  const selectedEntity = useAppStore((state) => state.selectedEntity);

  const navigate = useNavigate();

  useEffect(() => {
    if (!entityType && !entityId) {
      if (selectedEntity && isSelectedEntityValid(selectedEntity)) {
        navigate(`${selectedEntity.type}/${selectedEntity.id}`, { replace: true });
      }
    }
  }, [selectedEntity, entityId, entityType, navigate]);
  return (
    <>
      <div className="bg-liven-gray1 flex flex-col flex-shrink-0 w-[216px] border-r-[1px] border-liven-gray7">
        <div className="flex-1 flex flex-col min-h-0 overflow-y-auto">
          <div className="flex-1">
            <SidebarContainer />
          </div>
        </div>
      </div>
      <main className="h-full w-full">
        <Outlet />
      </main>
    </>
  );
};
