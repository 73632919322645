import moment from 'moment';
import { TimeInterval } from 'types/types';

import { Regions } from './constants';
import { getCookie } from './cookies';

export function capitalizeText(textToBeTransformed: string, separator: string): string {
  if (!textToBeTransformed) {
    return '';
  }

  let splitString = textToBeTransformed.split(separator);
  let result = splitString.map((str) => {
    return str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase();
  });

  return result.join(separator);
}

export const establishDateRange = (timestamp: string, interval: TimeInterval): string => {
  const parsedTimestamp = moment.utc(timestamp);
  switch (interval) {
    case 'day':
      return parsedTimestamp.format('DD MMM');
    case 'hour':
      return parsedTimestamp.format('hha DD MMM');
    case 'week':
      return `${parsedTimestamp.format('DD MMM')} - ${moment.utc(timestamp).add(6, 'days').format('DD MMM')}`;
    case 'month':
      return parsedTimestamp.format('MMM');
    case 'year':
      return parsedTimestamp.format('YYYY');
    default:
      const exhaustiveCheck: never = interval;
      throw new Error(`Unhandled date range interval: ${exhaustiveCheck}`);
  }
};

export const getClientTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

/**
 * @name arrayToRecord
 * @description Converts an array of objects to a record with the key being the value of the key param
 * @param array - the array to convert
 * @param key - the key to use as the record key
 * @returns a record with the key being the value of the key param
 */
export const arrayToRecord = <T>(array: T[], key: keyof T & string): Record<string, T> => {
  return array.reduce((acc, item) => {
    const keyValue = item[key];

    return {
      ...acc,
      [String(keyValue)]: item,
    };
  }, {});
};

/**
 * @name isRecordEmpty
 * @description Checks if a record is undefined, null or empty
 * @param record - the record to check
 * @returns true if the record is empty, false otherwise
 */
export const isRecordEmpty = <T>(record?: Record<string, T>): boolean => {
  if (!record) {
    return true;
  }

  return Object.keys(record).length === 0;
};

export const getTimeZone = (): string => {
  const regionCookie = getCookie('region');
  switch (regionCookie) {
    case Regions.SG:
      return 'Asia/Singapore';
    case Regions.US:
      return 'America/New_York';
    default:
      return 'Australia/Melbourne';
  }
};
