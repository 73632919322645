import styles from 'components/charts/tooltip/custom-tooltip.module.css';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

const BAR_CHART_ONE_DATA_KEY = 'barChartOneThisPeriod';
const LINE_CHART_ONE_DATA_KEY = 'lineChartOneThisPeriod';
const LINE_CHART_TWO_DATA_KEY = 'lineChartTwoThisPeriod';

const getPeriodKey = (dataKey: string) => {
  if (dataKey.includes(BAR_CHART_ONE_DATA_KEY)) {
    return BAR_CHART_ONE_DATA_KEY;
  } else if (dataKey.includes(LINE_CHART_ONE_DATA_KEY)) {
    return LINE_CHART_ONE_DATA_KEY;
  } else if (dataKey.includes(LINE_CHART_TWO_DATA_KEY)) {
    return LINE_CHART_TWO_DATA_KEY;
  }

  return dataKey;
};

export const CustomTooltip = <T extends ValueType, V extends NameType>({ active, payload }: TooltipProps<T, V>) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.customTooltip}>
        <div className={styles.customTooltipHeaderContainer}>
          <div className={styles.customTooltipHeading}>{payload[0].payload.interval}</div>
        </div>
        <div className={styles.customTooltipRowsContainer}>
          {payload.map(({ color, value, dataKey, payload: payloadData }) => {
            if (typeof dataKey === 'number') return null;
            if (!dataKey) return null;

            const isThisPeriod = true;

            const periodKey = getPeriodKey(dataKey);

            return (
              <div key={color} className={styles.customTooltipRow}>
                <div className={styles.customTooltipRowLabel}>
                  {isThisPeriod ? (
                    <span
                      className={styles.customTooltipStraightLine}
                      style={{
                        background: color,
                      }}
                    />
                  ) : (
                    <div className={styles.customTooltipDashedLineContainer}>
                      <span
                        className={styles.customTooltipDashedLine}
                        style={{
                          background: color,
                        }}
                      />
                      <span
                        className={styles.customTooltipDashedLine}
                        style={{
                          background: color,
                        }}
                      />
                      <span
                        className={styles.customTooltipDashedLine}
                        style={{
                          background: color,
                        }}
                      />
                    </div>
                  )}
                  <div>{value}</div>
                </div>
                <div className={styles.customTooltipPeriodLabel}>
                  {payloadData[periodKey]?.tooltipLabel || periodKey}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return null;
};
