import axios from 'axios';
import endpoints from 'services/endpoints';
import { IAnalyticsResponse, IChartDataRequestParams, INavItemsRequestParams } from 'types/types';

export const getAnalyticsData = (
  params: INavItemsRequestParams | IChartDataRequestParams,
  metricsSet: string,
): Promise<IAnalyticsResponseWrapper> => {
  return axios
    .get(endpoints.analytics.requestData(metricsSet), {
      params: params,
      withCredentials: true,
    })
    .then((response) => response.data);
};

export type AnalyticsDataRequestParams = {
  navigationItemParams: INavItemsRequestParams;
  chartsDataParams: IChartDataRequestParams;
  metricsSet: string;
};

export type IAnalyticsResponseWrapper = {
  data: IAnalyticsResponse[];
};

export const fetchDollarsAnalyticsData = (
  filter: AnalyticsDataRequestParams,
): Promise<[IAnalyticsResponse[], IAnalyticsResponse[]]> => {
  const { metricsSet, chartsDataParams, navigationItemParams } = filter;
  return Promise.all([
    getAnalyticsData(navigationItemParams, metricsSet),
    getAnalyticsData(chartsDataParams, metricsSet),
  ]).then((res: IAnalyticsResponseWrapper[]) => {
    return [res[0].data, res[1].data];
  });
};
