import moment from 'moment';

export const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const displayDateTime = (date: Date, isDate: boolean) => {
  let display = '';
  if (date && isDate) {
    display = moment(date).format('dddd, DD/MM/yyyy');
  } else if (date && !isDate) {
    display = moment(date).format('h:mma');
  }
  return display;
};

export const displayTime = (hours: number, minutes: number) => {
  return moment(`${hours}:${minutes}`, 'HH:mm').format('h:mma');
};

export const displaySchedulingTitle = (startDate: Date | null, endDate: Date | null) => {
  let display = (
    <span>
      Available to purchase from{' '}
      {startDate ? (
        <span className="text-liven-pink">
          {moment(startDate).format('DD/MM/yyyy')} {displayDateTime(startDate, false)}
        </span>
      ) : (
        'today'
      )}
      {endDate ? (
        <>
          {' '}
          to{' '}
          <span className="text-liven-pink">
            {moment(endDate).format('DD/MM/yyyy')} {displayDateTime(endDate, false)}
          </span>
        </>
      ) : (
        ''
      )}
      .
    </span>
  );
  return display;
};
export const displayRedemtionTitle = (days: string[], startTime: Date | null, endTime: Date | null) => {
  let display = (
    <span>
      Can be spent{' '}
      {days && days.length > 0 ? (
        <>
          on <span className="text-status-green">{days.join(', ')}</span>
        </>
      ) : (
        !startTime && !endTime && 'at any time'
      )}
      {startTime && endTime && (
        <>
          {' '}
          between{' '}
          <span className="text-status-green">
            {displayDateTime(startTime, false)} - {displayDateTime(endTime, false)}
          </span>
        </>
      )}
      .
    </span>
  );
  return display;
};
export const displayAvailabilityTitle = (units: string | null, userCap: boolean) => {
  let display = (
    <span>
      {units ? (
        <>
          <span className="text-liven-blue">{units}</span> units available
        </>
      ) : (
        'No limit on units'
      )}
      {userCap ? (
        <>
          , maximum <span className="text-liven-blue">one per customer</span>
        </>
      ) : units ? (
        '. No limit on purchase'
      ) : (
        ' or purchase'
      )}
      .
    </span>
  );
  return display;
};
