import React from 'react';

type Props = {
  handleRetry?: () => void;
  errorMessage?: string;
};
export const GenericError: React.FC<Props> = ({ handleRetry, errorMessage = 'Oops, something went wrong!!' }) => {
  return (
    <div className="w-full h-full flex justify-center items-center flex-col">
      <span className="text-2xl">{errorMessage}</span>
      {handleRetry && (
        <button
          className="w-28 flex justify-center py-2 px-4 border border-transparent rounded-full text-base font-semibold text-white bg-rose-500 hover:bg-rose-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500  ml-auto mr-auto disabled:bg-gray-300"
          onClick={handleRetry}
        >
          RETRY
        </button>
      )}
    </div>
  );
};
