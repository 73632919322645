import { useAppStore } from 'app-store';
import { useAuthValidator } from 'hooks/use-auth-validator';
import React, { useCallback } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserInfo } from 'types/types';

type Props = {
  children: JSX.Element;
};

export const Authenticated = ({ children }: Props) => {
  const onSuccessfulAuthentication = useCallback((userInfo: UserInfo) => {
    const setUserInfo = useAppStore.getState().setUserInfo;
    setUserInfo(userInfo);
  }, []);

  const location = useLocation();
  const isAuthenticated = useAuthValidator(onSuccessfulAuthentication);

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
