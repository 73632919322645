import styles from 'components/charts/impressions-aggregate.module.css';
import { AggregateAndPeriodData } from 'lib/data-transformers';
import { useState } from 'react';
import { CHART_COLORS } from 'utils/constants';

import { StackedAreaChart, StackedAreaChartData } from './StackedAreaChart/StackedAreaChart';

const DEFAULT_PAGE_SIZE = 10;

interface IImpressionsAggregateInsightChartProps {
  loading: boolean;
  retry?: () => void;
  aggregateAndPeriodData: AggregateAndPeriodData;
  visualizing: string;
  stacked: boolean;
  prefixYAxis?: string;
}

export const ImpressionsAggregateInsightChart = ({
  loading,
  aggregateAndPeriodData,
  visualizing,
  stacked,
  prefixYAxis = '',
}: IImpressionsAggregateInsightChartProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * DEFAULT_PAGE_SIZE;
  const endIndex = startIndex + DEFAULT_PAGE_SIZE;

  const { aggregateData, periodData: periodDataMetrics } = aggregateAndPeriodData || {};

  const periodData = periodDataMetrics[visualizing];
  const paginationData = aggregateData?.slice(startIndex, endIndex);

  const rowIds = paginationData?.map((a: any) => a.id);
  const periodPaginatedData = periodData?.map((item: StackedAreaChartData) => {
    return {
      interval: item.interval,
      tooltipHeader: item.tooltipHeader,
      ...Object.keys(item).reduce((acc, key) => {
        if (rowIds?.includes(key)) {
          acc[key] = item[key];
        }
        return acc;
      }, {} as Record<string, any>),
    };
  });
  const periodDataColors = paginationData?.reduce((acc: any, item: any, index: number) => {
    acc[item.id] = CHART_COLORS[index];
    return acc;
  }, {} as Record<string, string>);

  return (
    <>
      <div className="flex flex-col w-full max-w-[640px]">
        {paginationData && paginationData.length === 0 && (
          <div className={styles.wrapper}>
            <div className={styles.content}>Looks like you haven't had any transactions yet.</div>
          </div>
        )}
        {paginationData && paginationData.length > 0 && (
          <StackedAreaChart
            height={150}
            data={periodPaginatedData}
            margin={{ top: 0, right: 0, bottom: -18, left: 0 }}
            loading={loading}
            error={paginationData === null}
            colors={periodDataColors}
            columns={rowIds ?? []}
            stacked={stacked}
            prefixYAxis={prefixYAxis}
            hideXAxis={false}
          />
        )}
        <ul className="ml-4">
          {paginationData.map((row: any, indexRow) => (
            <li key={indexRow} className="inline-flex gap-2 items-center ml-7">
              <div style={{ backgroundColor: CHART_COLORS[indexRow % 10] }} className={`w-1.5 h-1.5 rounded-full`} />
              <span className="text-xs">
                Buy ${row['buy']} / Get ${row['get']}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
