import { ReactComponent as ThreeLayersSVG } from 'static/liven/assets/svg/three-layers.svg';

type Props = {
  className?: string;
};

export const ThreeLayers = ({ className = '' }: Props) => {
  return (
    <>
      <ThreeLayersSVG title="ThreeLayersSVG" className={className} />
    </>
  );
};
