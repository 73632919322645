import styles from 'components/charts/shared/empty.module.css';
import React from 'react';

interface IEmptyProps {
  title?: string;
}
export const Empty: React.FC<IEmptyProps> = ({ title = 'No data' }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>{title}</div>
    </div>
  );
};
