import React from 'react';
import { Bars } from 'react-loader-spinner';

type Props = {
  width?: string;
  height?: string;
  color?: string;
};

export const BarsLoader: React.FC<Props> = ({ width = '80', height = '80', color = '#ff3d6e' }) => {
  return (
    <div className="flex w-full h-full justify-center items-center">
      <Bars
        height={height}
        width={width}
        color={color}
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};
