import axios from 'axios';
import endpoints from 'services/endpoints';
import { Branch, BranchGroup, Entities, Merchant } from 'types/types';
import { arrayToRecord } from 'utils/utils';

export const getBranchGroups = async (): Promise<BranchGroup[]> => {
  try {
    const response = await axios.get(endpoints.entities.BRANCH_GROUPS);
    return response.data;
  } catch (e) {
    console.error(e);
    throw new Error('Error getting branch groups');
  }
};

export const getMerchants = async (): Promise<Merchant[]> => {
  try {
    const response = await axios.get(endpoints.entities.MERCHANTS);
    return response.data;
  } catch (e) {
    console.error(e);
    throw new Error('Error getting merchants');
  }
};

export const getAllBranches = async (): Promise<Branch[]> => {
  try {
    const response = await axios.get(endpoints.entities.ALL_BRANCHES);
    return response.data;
  } catch (e) {
    console.error(e);
    throw new Error('Error getting branches');
  }
};

export const loadEntities = (): Promise<Entities> =>
  Promise.all([getMerchants(), getAllBranches(), getBranchGroups()]).then(([merchants, branches, branchGroups]) => {
    const merchantsRecord = arrayToRecord<Merchant>(merchants ?? [], 'merchantId');
    const branchGroupsRecord = arrayToRecord<BranchGroup>(branchGroups ?? [], 'branchGroupId');
    const branchesRecord = arrayToRecord<Branch>(branches ?? [], 'branchId');
    return {
      merchants: merchantsRecord,
      branches: branchesRecord,
      branchGroups: branchGroupsRecord,
    };
  });

const entitiesService = {
  getAllBranches,
  getBranchGroups,
  getMerchants,
  loadEntities,
};

export default entitiesService;
