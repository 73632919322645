import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ChartLoader } from 'components/animation/loader/chart-loader';
import { ChartRetry } from 'components/animation/loader/chart-retry';
import { ComposedChartContainer } from 'components/charts/composed-chart';
import { Dropdown } from 'components/lib/dropdown';
import { transformBrandollarHeatMapData } from 'lib/data-transformers';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { getAnalyticsData } from 'services/merchant/analytics/analytics-service';
import { BrandollarMetrics, EntityType, IChartDataRequestParams } from 'types/types';
import {
  BRANCH_GROUPS,
  BRANDOLLARS_METRICS_VALUES,
  BRANDOLLAR_DEFAULT_METRIC,
  HOURS,
  MERCHANTS,
} from 'utils/constants';
import { isValidEntityType } from 'utils/entities';
import { BRANCH_GROUP_BRAND_DOLLARS_SET, MERCHANT_BRAND_DOLLARS_SET } from 'utils/metrics-sets';
import { getTimeZone } from 'utils/utils';

import { DAYS } from '../Brandollars/brandollar-helper';
import { getBaseAnalyticsRequestParams, getBranchGroupMeticName, getStartAndEndDate } from './analytics-utils';

export const BrandollarsHeatmap = ({ period, range }: { period: string; range?: DateRange | null }) => {
  const { entityId, entityType } = useParams();
  const [requestParams, setRequestParams] = useState<IChartDataRequestParams | null>(null);
  const [metric, setMetric] = useState<BrandollarMetrics>(BRANDOLLAR_DEFAULT_METRIC);
  const queryClient = useQueryClient();

  if (!entityId) {
    throw new Error('BrandollarsHeatmap - entityId is undefined');
  }

  if (!entityType || !isValidEntityType(entityType)) {
    throw new Error('BrandollarsHeatmap - entityType is undefined');
  }

  const getChartData = (period: string, metric: string, range?: DateRange | null) => {
    const { startDate, endDate } = getStartAndEndDate(period, range);
    const navigationItemParams = getBaseAnalyticsRequestParams(
      startDate,
      endDate,
      entityType === MERCHANTS ? `${metric}_${entityId}` : getBranchGroupMeticName(metric),
      entityId,
      getTimeZone(),
      entityType as EntityType,
      undefined,
      undefined,
      entityType === MERCHANTS ? 'merchant_id' : 'branch_group_id',
      entityType === MERCHANTS ? 'merchant_voucher' : undefined,
    );

    if (!navigationItemParams) {
      throw new Error('BrandollarsHeatmap - navigationItemParams is undefined');
    }
    setRequestParams({ ...navigationItemParams, interval: 'hour' });
  };

  useEffect(() => {
    getChartData(period, metric, range);
  }, [entityId, period, metric, range]);

  const {
    isError,
    isLoading,
    data: analyticsData,
  } = useQuery(
    ['brandollars-heatmap', requestParams],
    () =>
      getAnalyticsData(
        requestParams!,
        entityType === BRANCH_GROUPS ? BRANCH_GROUP_BRAND_DOLLARS_SET : MERCHANT_BRAND_DOLLARS_SET,
      ).then((data) => {
        return transformBrandollarHeatMapData(data.data);
      }),
    { enabled: requestParams !== null, staleTime: 60000 },
  );

  return (
    <ComposedChartContainer>
      <div className="relative min-h-[500px]">
        <div className="p-6 flex">
          <div className="flex flex-col gap-2">
            <h3 className="text-base text-liven-gray11 font-medium leading-4">Heatmap</h3>
            <h1 className="text-2xl text-liven-gray12 font-medium leading-6">Sales by hour</h1>
          </div>
          <div className="ml-auto flex flex-col gap-2">
            <div className="text-[10px] text-liven-gray9 text-right leading-4">Visualizing</div>
            <Dropdown
              id={'brandollars-visualizing'}
              handleSelectionChanged={(event) => {
                const value = event.target.value;
                setMetric(value as BrandollarMetrics);
              }}
              options={BRANDOLLARS_METRICS_VALUES}
              selectedValue={metric}
              labelText=""
            />
          </div>
        </div>
        {isLoading && <ChartLoader />}
        {!isLoading && !isError && (
          <table className="border-collapse table-fixed w-full">
            <thead>
              <tr>
                <td className="border border-gray7 px-4 py-3 text-sm font-medium"> </td>
                {DAYS.map((day) => {
                  return (
                    <td key={day} className="border border-[#DBDBDB] px-4 py-3 text-sm font-medium">
                      {day}
                    </td>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {analyticsData &&
                HOURS.map((hour, index) => {
                  return (
                    <tr key={index}>
                      <td className=" px-4 py-2 text-sm font-medium">{`0${hour}`.slice(-2)}</td>
                      {DAYS.map((day, index) => {
                        return (
                          <td
                            key={index}
                            className="px-4 py-2 text-xs font-normal"
                            style={{
                              backgroundColor: `rgba(255, 60, 110, ${
                                analyticsData.hourlyData[hour][DAYS.indexOf(day)] / analyticsData.max
                              })`,
                            }}
                          >
                            {`${metric !== 'voucher_counts' ? '$' : ''}${
                              analyticsData.hourlyData[hour][DAYS.indexOf(day)]
                            }`}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
        {isError && (
          <ChartRetry onErrorRetry={() => queryClient.invalidateQueries({ queryKey: ['brandollars-heatmap'] })} />
        )}
      </div>
    </ComposedChartContainer>
  );
};
