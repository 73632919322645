import { useAnalytics } from 'config/segment';
import { ChatTeardropText } from 'phosphor-react';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BRANCH_GROUPS, MERCHANTS } from 'utils/constants';

import { BrandollarsInsights } from '../analytics/brandollars-insights';
import { BrandDollarsVouchers } from './brandollars-vouchers';

export const BrandollarsMainContainer = () => {
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const { pathname } = useLocation();
  const { entityId, entityType } = useParams();

  if (!entityType && !entityId) {
    throw new Error('BrandollarMain - No entities found');
  }

  if (entityType !== MERCHANTS && entityType !== BRANCH_GROUPS) {
    throw new Error('BrandollarMain - Invalid entity type');
  }

  useEffect(() => {
    analytics.track('Viewed Brandollar Editor', {
      entity_id: entityId,
      entity_type: entityType,
      path: pathname,
      screen: 'Brandollars',
    });
  }, [analytics, entityId, entityType, pathname]);

  return (
    <div className="flex h-full w-full">
      <div className="flex flex-col w-full">
        <div className="px-12 pt-8 pb-5 border-b border-b-1 border-solid border-liven-gray5 bg-liven-gray1">
          <h1 className="text-3xl font-medium">Brandollars</h1>
        </div>
        <div className="bg-liven-gray2 flex flex-col pl-12 pt-2 pb-5 gap-6 h-full overflow-y-auto">
          {entityType === MERCHANTS && <BrandollarsInsights />}
          <h2 className="text-2xl font-medium">Manage Brandollars</h2>
          <p className="text-base font-normal text-liven-gray11">
            Welcome to the Brandollar Manager, your gateway to maximising customer engagement and boosting revenue.
          </p>
          <h2 className="text-2xl font-medium">Need some help?</h2>
          <p className="text-base font-normal text-liven-gray11">
            Have any questions or need help designing the best Brandollars for your business? Speak to a member of our
            team.
          </p>
          <div>
            <button
              type="button"
              data-testid="contact-support"
              onClick={() => {
                analytics.track('Support Clicked', {
                  entity_id: entityId,
                  entity_type: entityType,
                });
                navigate(`../contact`);
              }}
              className="bg-white flex rounded-md px-3 py-2 gap-2 mb-5  shadow-xsm items-center cursor-pointer text-sm border border-1 border-solid border-liven-gray6"
            >
              <ChatTeardropText size={20} weight="bold" className="text-liven-gray11" /> Contact Support
            </button>
          </div>
        </div>
      </div>
      <div className=" max-w-[470px] min-w-[470px]">
        <BrandDollarsVouchers />
      </div>
    </div>
  );
};
