import { AnalyticsBrowser } from '@segment/analytics-next';
import React from 'react';

const AnalyticsContext = React.createContext<AnalyticsBrowser>(undefined!);

type Props = {
  writeKey: string;
  children: React.ReactNode;
};

export const AnalyticsProvider = ({ writeKey, children }: Props) => {
  const analytics = React.useMemo(() => AnalyticsBrowser.load({ writeKey }), [writeKey]);

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

export const useAnalytics = () => {
  const result = React.useContext(AnalyticsContext);
  if (!result) {
    throw new Error('Context used outside of the provider');
  }

  return result;
};
