import { ArrowRight } from '@phosphor-icons/react';
import { StackedAreaChartData } from 'components/charts/StackedAreaChart/StackedAreaChart';
import {
  Branch,
  IAnalyticsResponse,
  IComposedChartData,
  INavigationItemTransformedResponse,
  IVoucher,
  TimeInterval,
} from 'types/types';
import {
  CASH_PAID,
  COUNT_OF_TRANSACTIONS,
  CREDITS_PURCHASED,
  CREDIT_REDEEMED,
  TRANSACTIONS,
  VOLUME_OF_TRANSACTIONS,
} from 'utils/chart-constants';
import { establishDateRange } from 'utils/utils';

const VOUCHER_TOTAL_SALES = 'voucher_total_sales';
const VOUCHER_TOTAL_CREDITS = 'voucher_total_credits';
const VOUCHER_COUNTS = 'voucher_counts';
const GROSS_SALES = 'gross_sales';
const TRANSACTION_COUNTS = 'transaction_counts';
const REDEEMED_AMOUNT = 'redemption_amount';
const TOTAL_CASH = 'total_cash';
const TOTAL_COUNT = 'total_count';
const TOTAL_CREDIT = 'total_credit';

type IGroupData = {
  [key: string]: IAnalyticsResponse[];
};

const ANALYTICS_NAV_ITEM_INFO: Record<string, INavigationItemTransformedResponse> = {
  gross_sales: {
    label: VOLUME_OF_TRANSACTIONS,
    valueType: 'dollar',
    fullWidth: true,
    shape: 'line',
    color: '#f33e75',
    thisPeriodValue: '',
  },
  transaction_counts: {
    label: COUNT_OF_TRANSACTIONS,
    valueType: 'number',
    fullWidth: true,
    shape: 'bar',
    color: '#2cb5cb',
    thisPeriodValue: '',
  },
  redemption_amount: {
    label: CREDIT_REDEEMED,
    valueType: 'dollar',
    fullWidth: true,
    shape: 'line',
    color: '#fe3bff',
    thisPeriodValue: '',
  },
};

// TODO: To make this transformer scalable, convert this to a POJO transformer library.

const getChartItemPropValues = (item: IAnalyticsResponse): INavigationItemTransformedResponse => {
  if (item.metric_name.startsWith(VOUCHER_TOTAL_CREDITS) || item.metric_name.startsWith(TOTAL_CREDIT)) {
    return {
      label: CREDITS_PURCHASED,
      thisPeriodValue: parseInt(item.metric_value).toString(),
      color: '#fe3bff',
      fullWidth: false,
      valueType: 'dollar',
      loading: false,
      shape: 'line',
    };
  }

  if (item.metric_name.startsWith(VOUCHER_TOTAL_SALES) || item.metric_name.startsWith(TOTAL_CASH)) {
    return {
      label: CASH_PAID,
      thisPeriodValue: parseInt(item.metric_value).toString(),
      color: '#f33e75',
      fullWidth: false,
      valueType: 'dollar',
      loading: false,
      shape: 'line',
    };
  }

  if (item.metric_name.startsWith(VOUCHER_COUNTS) || item.metric_name.startsWith(TOTAL_COUNT)) {
    return {
      label: TRANSACTIONS,
      thisPeriodValue: parseInt(item.metric_value).toString(),
      color: '#2cb5cb',
      fullWidth: true,
      valueType: 'number',
      loading: false,
      shape: 'bar',
    };
  }

  return {
    label: item.metric_name,
    thisPeriodValue: item.metric_value,
  };
};

const groupDataByKey = (
  data: IAnalyticsResponse[],
  key: 'metric_timestamp' | 'voucher_id' | 'metric_name' | 'branch_id',
): IGroupData => {
  return data.reduce((group, element: IAnalyticsResponse) => {
    const value = element[key];
    if (value) {
      group[value] = group[value] ?? [];
      group[value].push(element);
    }
    return group;
  }, {} as IGroupData);
};
export const transformChartNavigationAnalyticsData = (
  data: IAnalyticsResponse[],
): INavigationItemTransformedResponse[] | undefined => {
  if (data.length === 0) {
    data = [
      { metric_name: TOTAL_CREDIT, metric_value: '0', metric_timestamp: '' },
      { metric_name: TOTAL_CASH, metric_value: '0', metric_timestamp: '' },
      { metric_name: TOTAL_COUNT, metric_value: '0', metric_timestamp: '' },
    ];
  }
  return data
    .map((navigationItemResponse) => getChartItemPropValues(navigationItemResponse))
    .sort((a, b) => {
      if (a.label < b.label) {
        return 1;
      }
      if (a.label > b.label) {
        return -1;
      }
      return 0;
    });
};

export const transformInStoreVolumeNavigationItemsData = (data: IAnalyticsResponse[]) => {
  if (data.length === 0) {
    data = [
      { metric_name: 'gross_sales', metric_value: '0', metric_timestamp: '' },
      { metric_name: 'redemption_amount', metric_value: '0', metric_timestamp: '' },
      { metric_name: 'transaction_counts', metric_value: '0', metric_timestamp: '' },
    ];
  }
  return data.reverse().map((analyticsItem) => {
    return {
      ...ANALYTICS_NAV_ITEM_INFO[analyticsItem.metric_name],
      thisPeriodValue: parseInt(analyticsItem.metric_value).toString(),
    } as INavigationItemTransformedResponse;
  });
};

export const transformInStoreVolumeChartsData = (data: IAnalyticsResponse[], interval: TimeInterval) => {
  const transformedAnalyticsPayload: IComposedChartData[] = [];
  const groupedData = groupDataByKey(data, 'metric_timestamp');

  for (const [key, value] of Object.entries(groupedData)) {
    const intervalKey = establishDateRange(key, interval);
    let transformedAnalyticsItem: IComposedChartData = {
      interval: intervalKey,
    };

    value.forEach((analyticsItem) => {
      if (analyticsItem.metric_name === TRANSACTION_COUNTS) {
        transformedAnalyticsItem.barChartOneThisPeriod = {
          value: parseInt(analyticsItem.metric_value),
          tooltipLabel: COUNT_OF_TRANSACTIONS,
        };
      } else if (analyticsItem.metric_name === GROSS_SALES) {
        transformedAnalyticsItem.lineChartOneThisPeriod = {
          value: parseInt(analyticsItem.metric_value),
          tooltipLabel: VOLUME_OF_TRANSACTIONS,
        };
      } else if (analyticsItem.metric_name === REDEEMED_AMOUNT) {
        transformedAnalyticsItem.lineChartTwoThisPeriod = {
          value: parseInt(analyticsItem.metric_value),
          tooltipLabel: CREDIT_REDEEMED,
        };
      }
    });
    transformedAnalyticsPayload.push(transformedAnalyticsItem);
  }

  return transformedAnalyticsPayload;
};
export const transformDollarsAnalyticsData = (
  data: IAnalyticsResponse[],
  interval: TimeInterval,
): IComposedChartData[] | undefined => {
  const transformedAnalyticsPayload: IComposedChartData[] = [];
  const groupedData = groupDataByKey(data, 'metric_timestamp');

  for (const [key, value] of Object.entries(groupedData)) {
    const intervalKey = establishDateRange(key, interval);
    let transformedAnalyticsItem: IComposedChartData = {
      interval: intervalKey,
    };

    value.forEach((analyticsItem) => {
      if (analyticsItem.metric_name.startsWith(VOUCHER_COUNTS) || analyticsItem.metric_name.startsWith(TOTAL_COUNT)) {
        transformedAnalyticsItem.barChartOneThisPeriod = {
          value: parseInt(analyticsItem.metric_value),
          tooltipLabel: TRANSACTIONS,
        };
      } else if (
        analyticsItem.metric_name.startsWith(VOUCHER_TOTAL_SALES) ||
        analyticsItem.metric_name.startsWith(TOTAL_CASH)
      ) {
        transformedAnalyticsItem.lineChartOneThisPeriod = {
          value: parseInt(analyticsItem.metric_value),
          tooltipLabel: CASH_PAID,
        };
      } else if (
        analyticsItem.metric_name.startsWith(VOUCHER_TOTAL_CREDITS) ||
        analyticsItem.metric_name.startsWith(TOTAL_CREDIT)
      ) {
        transformedAnalyticsItem.lineChartTwoThisPeriod = {
          value: parseInt(analyticsItem.metric_value),
          tooltipLabel: CREDITS_PURCHASED,
        };
      }
    });
    transformedAnalyticsPayload.push(transformedAnalyticsItem);
  }

  return transformedAnalyticsPayload;
};

export const transformBrandollarHeatMapData = (data: IAnalyticsResponse[]) => {
  const hourlyData = [...Array(24)].map(() => Array(7).fill(0));
  let max = 1;
  for (let index = 0; index < data.length; index++) {
    const row = data[index];
    const date = new Date(row.metric_timestamp);
    const day = date.getUTCDay() === 0 ? 6 : date.getUTCDay() - 1;
    const hour = date.getUTCHours();
    const value = parseInt(row.metric_value);
    hourlyData[hour][day] += value;
  }
  hourlyData.forEach((el) => {
    el.forEach((value) => {
      if (max < value) max = value;
    });
  });

  return { hourlyData, max };
};

type StackedAreaChartGroup = { [key: string]: StackedAreaChartData[] };

export type AggregateAndPeriodData = {
  aggregateData: { [key: string]: React.ReactNode | string }[];
  periodData: StackedAreaChartGroup;
};

export const transformBreakdownPeriodData = (
  data: IAnalyticsResponse[],
  interval: TimeInterval,
  brandollars: { [id: number]: IVoucher } | undefined,
  branches: { [id: number]: Branch } | undefined,
  type: 'voucher' | 'branch' = 'voucher',
): StackedAreaChartGroup => {
  const metricGroupData = groupDataByKey(data, 'metric_name');
  let response: StackedAreaChartGroup = {};
  for (const [metricDataKey, metricDataValue] of Object.entries(metricGroupData)) {
    const groupedData = groupDataByKey(metricDataValue, 'metric_timestamp');
    const stackedAreaChartDataArray: StackedAreaChartData[] = [];
    for (const [key, value] of Object.entries(groupedData)) {
      const intervalKey = establishDateRange(key, interval);
      const stackedAreaChartData: StackedAreaChartData = { interval: intervalKey, tooltipHeader: intervalKey };
      if (type === 'voucher') {
        value.forEach((analyticsItem: IAnalyticsResponse) => {
          const voucher = brandollars?.[analyticsItem.voucher_id!];
          stackedAreaChartData[analyticsItem.voucher_id!] = {
            value: parseInt(analyticsItem.metric_value),
            tooltipLabel: voucher ? `$${voucher.price} • $${voucher.credit}` : `${analyticsItem.voucher_id!}`,
          };
        });
      } else {
        value.forEach((analyticsItem: IAnalyticsResponse) => {
          const branch = branches?.[analyticsItem.branch_id!];
          stackedAreaChartData[analyticsItem.branch_id!] = {
            value: parseInt(analyticsItem.metric_value),
            tooltipLabel: branch ? `${branch.merchantName} - ${branch.branchName}` : `${analyticsItem.branch_id!}`,
          };
        });
      }

      stackedAreaChartDataArray.push(stackedAreaChartData);
    }
    if (stackedAreaChartDataArray) response[metricDataKey] = stackedAreaChartDataArray;
  }

  return response;
};

export const transformAggregateAndPeriodBrandollarData = (
  data: IAnalyticsResponse[],
  interval: TimeInterval,
  brandollars: { [id: number]: IVoucher } | undefined,
): AggregateAndPeriodData => {
  const periodData = transformBreakdownPeriodData(data, interval, brandollars, undefined, 'voucher');
  const groupedData = groupDataByKey(data, 'voucher_id');
  const agregatedData: { [key: string]: React.ReactNode | string }[] = [];
  let index = 0;
  for (const [key, value] of Object.entries(groupedData)) {
    const agregatedValues = value.reduce((group, element) => {
      const metric = element.metric_name;
      const value = element.metric_value;
      if (metric.startsWith('voucher_counts') || metric === 'total_count') {
        group['transactions'] = group['transactions'] ?? 0;
        group['transactions'] += parseInt(value);
      } else if (metric.startsWith('voucher_total_sales') || metric === 'total_cash') {
        group['cash_paid'] = group['cash_paid'] ?? 0;
        group['cash_paid'] += parseInt(value);
      } else if (metric.startsWith('voucher_total_credits') || metric === 'total_credit') {
        group['credit_purchased'] = group['credit_purchased'] ?? 0;
        group['credit_purchased'] += parseInt(value);
      }
      return group;
    }, {} as { [key: string]: number });
    const voucher = brandollars?.[+key];
    agregatedData.push({
      ...agregatedValues,
      id: key,
      get: voucher ? `${voucher.credit}` : '$0',
      buy: voucher ? `${voucher.price}` : '$0',
      limits: (
        <div className="inline-flex ">
          {voucher?.userCap ? (
            <>
              <span className="rounded-full bg-[#CCF3F0] text-[#008277] py-1 px-2 text-xs leading-3">Yes</span>
              <ArrowRight size={16} />
            </>
          ) : (
            <>
              <span className="rounded-full bg-liven-gray3 text-liven-gray11 py-1 px-2 text-xs leading-3">No</span>
              <ArrowRight size={16} />
            </>
          )}
        </div>
      ),
      icon: 'ICON_PLACEHOLDER',
    });

    index++;
  }

  return { aggregateData: agregatedData, periodData };
};

export const transformAggregateAndPeriodInStoreData = (
  data: IAnalyticsResponse[],
  interval: TimeInterval,
  branches: { [id: number]: Branch },
): AggregateAndPeriodData => {
  const periodData = transformBreakdownPeriodData(data, interval, undefined, branches, 'branch');
  const groupedData = groupDataByKey(data, 'branch_id');
  const agregatedData: { [key: string]: React.ReactNode | string }[] = [];
  let index = 0;
  for (const [key, value] of Object.entries(groupedData)) {
    const agregatedValues = value.reduce((group, element) => {
      const metric = element.metric_name;
      const value = element.metric_value;
      if (metric === 'transaction_counts') {
        group['transaction_counts'] = group['transaction_counts'] ?? 0;
        group['transaction_counts'] += parseInt(value);
      } else if (metric === 'gross_sales') {
        group['gross_sales'] = group['gross_sales'] ?? 0;
        group['gross_sales'] += parseInt(value);
      } else if (metric === 'redemption_amount') {
        group['redemption_amount'] = group['redemption_amount'] ?? 0;
        group['redemption_amount'] += parseInt(value);
      }
      return group;
    }, {} as { [key: string]: number });
    const branch = branches?.[+key];
    agregatedData.push({
      ...agregatedValues,
      id: key,
      store: branch ? `${branch.merchantName} - ${branch.branchName}` : `${key}`,
      icon: 'ICON_PLACEHOLDER',
    });

    index++;
  }

  return { aggregateData: agregatedData, periodData };
};
